//ACTION TYPES--------------------------
const GET_MEMBER = 'GET_MEMBER';
const CLEAR_MEMBERS = 'CLEAR_MEMBERS';

const gotMember = member => ({ type: GET_MEMBER, member });
export const clearMembers = () => ({ type: CLEAR_MEMBERS });
//THUNK CREATORS------------------------
export const fetchMembers = groupId => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    //grab members from firestore
    firestore
      .collection('groups')
      .doc(groupId)
      .collection('members')
      .get()
      .then(members => {
        //I'm inside the members collection for the selected group
        members.docs.forEach(groupMember => {
          dispatch(gotMember(groupMember.data()));
        });
      });
  };
};

export const makeMember = (groupId, userId, groupName) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firestore
      .collection('groups')
      .doc(groupId)
      .collection('members')
      .get()
      .then(members => {
        members.docs.forEach(groupMember => {
          let memberData = groupMember.data();
          if (memberData.userId === userId) {
            //edit/update their approvedMember status here
            firestore
              .collection('groups')
              .doc(groupId)
              .collection('members')
              .doc(groupMember.id)
              .update({ approvedMember: true });
          }
        });
      })
      .then(() => {
        dispatch(clearMembers());
        dispatch(fetchMembers(groupId));
      });
  };
};

export const removeMember = (groupId, userId, groupName) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firestore
      .collection('groups')
      .doc(groupId)
      .collection('members')
      .get()
      .then(members => {
        members.docs.forEach(groupMember => {
          let memberData = groupMember.data();
          if (memberData.userId === userId) {
            firestore
              .collection('groups')
              .doc(groupId)
              .collection('members')
              .doc(groupMember.id)
              .delete();
          }
        });
      })
      .then(() => {
        dispatch(clearMembers());
        dispatch(fetchMembers(groupId));
      });
  };
};

export const makeLeader = (groupId, userId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection('groups')
      .doc(groupId)
      .collection('members')
      .get()
      .then(members => {
        members.docs.forEach(groupMember => {
          let memberData = groupMember.data();
          if (memberData.userId === userId) {
            firestore
              .collection('groups')
              .doc(groupId)
              .collection('members')
              .doc(groupMember.id)
              .update({ isLeader: true });
          }
        });
      })
      .then(() => {
        dispatch(clearMembers());
        dispatch(fetchMembers(groupId));
      });
  };
};

export const removeLeader = (groupId, userId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection('groups')
      .doc(groupId)
      .collection('members')
      .get()
      .then(members => {
        members.docs.forEach(groupMember => {
          let memberData = groupMember.data();
          if (memberData.userId === userId) {
            firestore
              .collection('groups')
              .doc(groupId)
              .collection('members')
              .doc(groupMember.id)
              .update({ isLeader: false });
          }
        });
      })
      .then(() => {
        dispatch(clearMembers());
        dispatch(fetchMembers(groupId));
      });
  };
};

//INITIAL STATE-------------------------
const initState = {
  groupMembers: [],
};

//REDUCER-------------------------------
const memberReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_MEMBER:
      return {
        ...state,
        groupMembers: [...state.groupMembers, action.member],
      };
    case CLEAR_MEMBERS:
      return {
        ...state,
        groupMembers: [],
      };
    default:
      return state;
  }
};
export default memberReducer;
