import React from 'react';
import { connect } from 'react-redux';
import { makeLeader, removeMember } from '../../store/Reducers/memberReducer';

const MemberCard = props => {
  const handleRemoveMemberClick = e => {
    e.preventDefault();
    props.removeMember(props.groupId, props.member.userId, props.groupName);
  };
  const handleMakeLeaderClick = e => {
    e.preventDefault();
    props.makeLeader(props.groupId, props.member.userId);
    // location.reload();
  };

  let name = props.member.name;
  if (props)
    return (
      <div>
        <h1 className="title is-4">{name}</h1>
        <button className="button is-small" onClick={handleRemoveMemberClick}>
          Remove
        </button>
        <button className="button is-small" onClick={handleMakeLeaderClick}>
          Make Leader
        </button>
      </div>
    );
};

const mapDispatchToProps = dispatch => {
  return {
    makeLeader: (groupId, userId) => dispatch(makeLeader(groupId, userId)),
    removeMember: (groupId, userId, groupName) =>
      dispatch(removeMember(groupId, userId, groupName)),
  };
};

export default connect(null, mapDispatchToProps)(MemberCard);
