import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../history';

const prayerCardElements = {
  display: 'flex',
};

const PrayerInfo = {
  flex: '4',
};
const PrayerDelete = {
  flex: '1',
};

class DiscussionCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClick = (event, discussion) => {
    event.preventDefault();
    history.push('/' + discussion.type + '/' + discussion.id);
  };

  render() {
    const { discussion } = this.props;
    return (
      <div>
        <div style={prayerCardElements}>
          <div
            style={PrayerInfo}
            onClick={event => {
              this.handleClick(event, discussion);
            }}
          >
            <h1 className="title is-4">{discussion.discussionTitle}</h1>
          </div>
        </div>
        <hr />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    group: state.group,
  };
};

export default connect(mapStateToProps)(DiscussionCard);
