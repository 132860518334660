import history from '../../history';

//ACTION TYPES--------------------------
const ADD_DISCUSSION = 'ADD_DISCUSSION';
const ADD_DISCUSSION_ERROR = 'ADD_DISCUSSION_ERROR';
const LOAD_ALL_DISCUSSIONS = 'LOAD_ALL_DISCUSSIONS';
const LOAD_SINGLE_DISCUSSION = 'LOAD_SINGLE_DISCUSSION';
const REMOVE_DISCUSSION = 'REMOVE_DISCUSSION';

const UPDATE_BANNER = 'UPDATE_BANNER';

//ACTION CREATORS-----------------------
const addDiscussion = discussion => ({ type: ADD_DISCUSSION, discussion });
const loadAllDiscussions = discussionsArray => ({
  type: LOAD_ALL_DISCUSSIONS,
  discussionsArray,
});
const loadDiscussion = discussion => ({
  type: LOAD_SINGLE_DISCUSSION,
  discussion,
});
const removeDiscussion = discussionId => ({
  type: REMOVE_DISCUSSION,
  discussionId,
});
const loadBanner = bannerInfo => ({
  type: UPDATE_BANNER,
  bannerInfo,
});

//THUNK CREATORS------------------------
export const loadBannerInfo = groupId => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();

  firestore
    .collection('groups')
    .doc(groupId)
    .collection('banner')
    .doc('bannerInfo')
    .get()
    .then(resp => {
      dispatch(loadBanner(resp.data()));
    });
};

export const updateBannerInfo = (info, groupId) => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();

  firestore
    .collection('groups')
    .doc(groupId)
    .collection('banner')
    .doc('bannerInfo')
    .update({
      eventAddress: info.eventAddress,
      eventDate: info.eventDate,
      eventInfo: info.eventInfo,
      eventTitle: info.eventTitle,
    })
    .then(() => {
      dispatch(loadBanner(info));
    });
};

export const createDiscussion = (discussion, group) => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();
  const profile = getState().firebase.profile;
  const authorId = getState().firebase.auth.uid;

  let displayAuthor = 'Group Member';
  if (discussion.displayAuthor !== '') {
    displayAuthor = discussion.displayAuthor;
  }
  discussion.displayAuthor = displayAuthor;

  firestore
    .collection('groups')
    .doc(group.groupId)
    .collection('bannerDiscussions')
    .add({
      ...discussion,
      author: profile.name,
      authorId: authorId,
      createdAt: new Date(),
      groupId: group.groupId,
    })
    .then(resp => {
      dispatch(
        addDiscussion({
          ...discussion,
          discussionId: resp.id,
          author: profile.name,
          authorId: authorId,
          createdAt: new Date(),
          groupId: group.groupId,
        })
      );
      history.push('/banner');
    })

    .catch(err => {
      dispatch({ type: ADD_DISCUSSION_ERROR, err });
    });
};

export const fetchAllDiscussions = (groupId, limit) => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();

  const discussionRef = firestore
    .collection('groups')
    .doc(groupId)
    .collection('bannerDiscussions');

  const query = discussionRef.orderBy('createdAt', 'desc').limit(limit);

  query.get().then(discussions => {
    let returnArray = [];
    discussions.docs.forEach(discussion => {
      returnArray.push({ ...discussion.data(), discussionId: discussion.id });
    });
    dispatch(
      loadAllDiscussions(
        returnArray.sort((a, b) => {
          return b.createdAt.seconds - a.createdAt.seconds;
        })
      )
    );
  });
};

export const fetchSingleDiscussion = (discussionId, groupId) => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();
  const profile = getState().firebase.profile;
  const authorId = getState().firebase.auth.uid;

  firestore
    .collection('groups')
    .doc(groupId)
    .collection('bannerDiscussions')
    .doc(discussionId)
    .get()
    .then(discussion => {
      dispatch(
        loadDiscussion({ ...discussion.data(), discussionId: discussion.id })
      );
    });
};

export const deleteDiscussion = (discussionId, groupId) => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();

  firestore
    .collection('groups')
    .doc(groupId)
    .collection('banner')
    .doc(discussionId)
    .collection('comments')
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        firestore
          .collection('groups')
          .doc(groupId)
          .collection('bannerDiscussions')
          .doc(discussionId)
          .collection('comments')
          .doc(doc.id)
          .delete();
      });
    })
    .then(() => {
      firestore
        .collection('groups')
        .doc(groupId)
        .collection('bannerDiscussions')
        .doc(discussionId)
        .delete()
        .then(() => {
          dispatch(removeDiscussion(discussionId));
        });
    });
};

//INITIAL STATE-------------------------

const initState = {
  discussions: [],
  singleDiscussion: {},
  bannerInfo: {},
};

//REDUCER-------------------------------

const bannerReducer = (state = initState, action) => {
  switch (action.type) {
    case ADD_DISCUSSION:
      return {
        ...state,
        discussions: [action.discussion, ...state.discussions],
      };
    case LOAD_ALL_DISCUSSIONS:
      return {
        ...state,
        discussions: action.discussionsArray,
      };
    case LOAD_SINGLE_DISCUSSION:
      return {
        ...state,
        singleDiscussion: action.discussion,
      };
    case REMOVE_DISCUSSION:
      return {
        ...state,
        discussions: state.discussions.filter(discussion => {
          return discussion.discussionId !== action.discussionId;
        }),
      };
    case UPDATE_BANNER:
      return {
        ...state,
        bannerInfo: action.bannerInfo,
      };
    default:
      return state;
  }
};
export default bannerReducer;
