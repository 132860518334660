import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createBannerDiscussionComment } from '../../store/Reducers/commentReducer';

const commentFormInputs = {
  display: 'flex',
  flexDirection: 'column',
};
const commentTextArea = {};

const addCommentButton = {
  textAlign: 'center',
};
const bannerDiscussionTitleArea = {
  flex: '1',
  width: '100%',
};
class CreateBannerDiscussionComment extends Component {
  state = {
    commentText: '',
    displayAuthor: '',
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  submit = e => {
    e.preventDefault();
    if (this.state.commentText) {
      if (this.state.displayAuthor === '') {
        this.state.displayAuthor = 'Group Member';
      }
      this.props.createBannerDiscussionComment(
        this.props.discussion.discussionId,
        this.props.groupId,
        this.props.userId,
        this.state.commentText,
        this.state.displayAuthor,
        this.props.discussion.title
      );
      document.getElementById('displayAuthor').value = '';
      document.getElementById('commentText').value = '';
      this.setState({
        commentText: '',
        displayAuthor: '',
      });
    }
  };
  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div style={commentFormInputs}>
            <div className="control">
              <label>Your Name</label>
              <input
                className={'input'}
                placeholder="Optional"
                type="text"
                id="displayAuthor"
                name="displayAuthor"
                onChange={this.handleChange}
                style={bannerDiscussionTitleArea}
              />
            </div>
            <label>Comment</label>
            <textarea
              className="textarea"
              placeholder="Write a comment here..."
              type="text"
              id="commentText"
              name="commentText"
              onChange={this.handleChange}
              style={commentTextArea}
            />
          </div>
        </form>
        <div style={addCommentButton}>
          <button className="button" onClick={this.submit}>
            Submit
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    userId: state.firebase.auth.uid,
    profile: state.firebase.profile,
    groupId: state.group.selectedGroup.groupId,
    discussion: state.banner.singleDiscussion,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createBannerDiscussionComment(
      discussionId,
      groupId,
      userId,
      commentText,
      displayAuthor,
      discussionTitle
    ) {
      dispatch(
        createBannerDiscussionComment(
          discussionId,
          groupId,
          userId,
          commentText,
          displayAuthor,
          discussionTitle
        )
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateBannerDiscussionComment);
