import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createPrayer } from '../../store/Reducers/prayerReducer';
import { createMail } from '../../store/Reducers/mailReducer';

const formInputs = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'left',
};
const prayerTitleArea = {
  flex: '1',
  width: '100%',
};

const prayerTextArea = {
  flex: '1',
  // alignItems: 'stretch',
  // flexBasis: '100%',
  width: '100%',
  // width: '400px',
};

class AddPrayerForm extends Component {
  state = {
    title: '',
    displayAuthor: '',
    prayerText: '',
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    if (this.state.title) {
      this.props.addPrayer(this.state, this.props.group.selectedGroup);
      this.props.createMail(this.props.group.selectedGroup, 'prayer');

      document.getElementById('title').value = '';
      document.getElementById('displayAuthor').value = '';
      document.getElementById('prayerText').value = '';

      this.setState({
        title: '',
        displayAuthor: '',
        prayerText: '',
      });
    }
  };
  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <h5 className="title is-4">Add A Prayer</h5>
          <div style={formInputs}>
            {/* <div> */}
            <div className="control">
              <label>Title</label>
              <input
                className={'input'}
                placeholder="Prayer Title"
                type="text"
                id="title"
                name="title"
                onChange={this.handleChange}
                style={prayerTitleArea}
              />
            </div>
            {/* </div> */}
            <div className="control">
              <label>Your Name</label>
              <input
                className={'input'}
                placeholder="Optional"
                type="text"
                id="displayAuthor"
                name="displayAuthor"
                onChange={this.handleChange}
                style={prayerTitleArea}
              />
            </div>
            {/* <div> */}
            <div className="control">
              <label>Prayer Text</label>
              <textarea
                className="textarea"
                placeholder="Write your prayer here..."
                type="text"
                id="prayerText"
                name="prayerText"
                onChange={this.handleChange}
                style={prayerTextArea}
              />
            </div>
            {/* </div> */}
          </div>

          <div>
            <button className="button">Add</button>
          </div>
        </form>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    group: state.group,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addPrayer(prayer, group) {
      dispatch(createPrayer(prayer, group));
    },
    createMail: (selectedGroup, sectionName) =>
      dispatch(createMail(selectedGroup, sectionName)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPrayerForm);
