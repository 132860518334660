import React, { Component } from 'react';
import { connect } from 'react-redux';
import MyGroupCard from './MyGroupCard';
import { selectGroup } from '../../store/Reducers/groupReducer';
import { fetchMyPrayersInGroup } from '../../store/Reducers/prayerReducer';
import { fetchRecentlyDiscussedInGroup } from '../../store/Reducers/recentlyDiscussedReducer';
import { fetchGroupNotificationStateForUser } from '../../store/Reducers/notificationReducer';

const headerBox = {
  display: 'flex',
  flexDirection: 'row',
};

class MyGroups extends Component {
  state = {
    selectedGroup: '',
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  divClick = (event, name, groupId, isLeader, memberStatus) => {
    event.preventDefault();

    if (memberStatus === 'pending') return;

    this.props.selectGroup(name, groupId, isLeader);
    this.setState({
      selectedGroup: name,
    });
    this.props.fetchMyPrayersInGroup(groupId, 5);
    this.props.fetchRecentlyDiscussedInGroup(groupId);
    this.props.fetchGroupNotificationStateForUser(groupId);
  };

  render() {
    const { myGroups, selectedGroup } = this.props.group;
    return (
      <div className={'myGroups'}>
        <div className={'box'}>
          <h4 className="title is-3">My Groups</h4>

          {myGroups.map((group, index) => {
            return (
              <div
                className={
                  selectedGroup.groupName === group.groupName ? 'selected' : ''
                }
                onClick={event => {
                  this.divClick(
                    event,
                    group.groupName,
                    group.groupId,
                    group.isLeader,
                    group.approvedMember
                  );
                }}
                key={
                  group.approvedMember !== 'pending'
                    ? group.groupId
                    : 'pending' + index
                }
              >
                <MyGroupCard group={group} selectedGroup={selectedGroup} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    group: state.group,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectGroup: (groupName, id, leaders) =>
      dispatch(selectGroup(groupName, id, leaders)),
    fetchMyPrayersInGroup: (groupId, limit) =>
      dispatch(fetchMyPrayersInGroup(groupId, limit)),
    fetchRecentlyDiscussedInGroup: groupId =>
      dispatch(fetchRecentlyDiscussedInGroup(groupId)),
    fetchGroupNotificationStateForUser: groupId =>
      dispatch(fetchGroupNotificationStateForUser(groupId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyGroups);
