import React, { Component } from 'react';

class PhotoDashboard extends Component {
  render() {
    return (
      <div>
        <h1>Group Photos</h1>
        <p>Here is a place to upload photos of group events</p>
      </div>
    );
  }
}
export default PhotoDashboard;
