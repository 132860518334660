import React, { Component } from 'react';
import PrayerList from './PrayerList';
import AddPrayerForm from './AddPrayerForm';

import { connect } from 'react-redux';
import { fetchAllPrayers } from '../../store/Reducers/prayerReducer';

const prayerBox = {
  marginRight: '20px',
  marginTop: '20px',
  marginBottom: '40px',
  marginLeft: '20px',
};

const PrayerDashboardComp = {
  display: 'flex',
  flexWrap: 'wrap',
};

const prayerForm = {
  flex: '1',
  marginBottom: '40px',
  flexBasis: '250px',
};
const prayerListSection = {
  flexDirection: 'column',
  flex: '1',
  marginRight: '20px',
  marginLeft: '20px',
  flexBasis: '200px',
  justifyContent: 'left',
};

const loadMorePrayersButton = {
  marginTop: '20px',
  marginBottom: '20px',
  textAlign: 'center',
};

class PrayerDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = { limit: 5 };
  }

  componentDidMount() {
    this.props.fetchGroupPrayers(
      this.props.group.selectedGroup.groupId,
      this.state.limit
    );
    this.state.limit += 5;
  }
  clickLoadMorePrayers = e => {
    e.preventDefault();
    this.props.fetchGroupPrayers(
      this.props.group.selectedGroup.groupId,
      this.state.limit
    );
    this.state.limit += 5;
  };

  render() {
    const { prayers } = this.props;
    return (
      <div className={'box'} style={prayerBox}>
        <h1 className={'title is-3'}>Prayer Requests</h1>
        <div style={PrayerDashboardComp}>
          <div style={prayerForm}>
            <AddPrayerForm />
          </div>
          <div style={prayerListSection}>
            <PrayerList prayers={prayers} />
            <div style={loadMorePrayersButton}>
              <button className="button" onClick={this.clickLoadMorePrayers}>
                Load More
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    group: state.group,
    prayers: state.prayer.groupPrayers,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchGroupPrayers: (groupId, limit) =>
      dispatch(fetchAllPrayers(groupId, limit)),
  };
};

// export default compose(
//   firestoreConnect([{ collection: 'prayers', orderBy: ['createdAt', 'desc'] }]),
//   connect(mapStateToProps)
// )(PrayerDashboard);
export default connect(mapStateToProps, mapDispatchToProps)(PrayerDashboard);
