import React, { Component } from 'react';
import { connect } from 'react-redux';
import DiscussionCard from './DiscussionCard';
import { fetchRecentlyDiscussedInGroup } from '../../store/Reducers/recentlyDiscussedReducer';

class RecentlyDiscussed extends Component {
  constructor(props) {
    super(props);
    this.state = { limit: 10 };
  }
  componentDidMount() {
    const currentGroupId = this.props.group.selectedGroup.groupId;

    if (currentGroupId) {
      this.props.fetchRecentlyDiscussedInGroup(currentGroupId);
    }
  }

  render() {
    const recentlyDiscussed = this.props.recentlyDiscussed.recentlyDiscussed;
    const selectedGroupId = this.props.group.selectedGroup.groupId;
    if (selectedGroupId) {
      return (
        <div className={'myGroups'}>
          <div className={'box'}>
            <h4 className="title is-3">Recently Discussed</h4>
            {recentlyDiscussed.map(discussion => {
              return (
                <div key={discussion.id}>
                  <DiscussionCard discussion={discussion} />
                </div>
              );
            })}
          </div>
        </div>
      );
    } else {
      return (
        <div className={'myGroups'}>
          <div className={'box'}>
            <h4 className="title is-3">Recently Discussed</h4>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    group: state.group,
    recentlyDiscussed: state.recentlyDiscussed,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchRecentlyDiscussedInGroup: groupId =>
      dispatch(fetchRecentlyDiscussedInGroup(groupId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecentlyDiscussed);
