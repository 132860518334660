import React, { Component } from 'react';
import ScriptureDiscussionComment from './ScriptureDiscussionComment';
import { connect } from 'react-redux';
import CreateScriptureDiscussionComment from './CreateScriptureDiscussionComment';
import { fetchScriptureDiscussionComments } from '../../store/Reducers/commentReducer';
import { fetchSingleDiscussion } from '../../store/Reducers/scriptureReducer';

const discussionDetail = {
  display: 'flex',
  flexWrap: 'wrap',
};
const discussionText = {
  flex: '1',
  marginRight: '40px',
  marginTop: '40px',
  marginBottom: '40px',
  marginLeft: '40px',
};
const discussionComments = {
  flexDirection: 'column',
  flex: '1',
  marginRight: '35px',
  marginLeft: '35px',
  marginBottom: '40px',
};

class ScriptureDiscussionDetail extends Component {
  componentDidMount() {
    this.props.fetchScriptureDiscussionComments(
      this.props.discussionId,
      this.props.groupId
    );
    this.props.fetchSingleDiscussion(
      this.props.discussionId,
      this.props.groupId
    );
  }

  render() {
    const { discussion } = this.props;
    const comments = this.props.comments;
    if (discussion) {
      return (
        <div style={discussionDetail}>
          <div style={discussionText}>
            <div className={'box'}>
              <span className="title is-4">{discussion.title}</span>
              <p>posted by {discussion.displayAuthor}</p>
              <br />
              <p>{discussion.discussionText}</p>
            </div>
          </div>

          <div style={discussionComments}>
            <div className={'box'}>
              <CreateScriptureDiscussionComment />
            </div>
            {comments &&
              comments.map((comment, index) => {
                return (
                  <ScriptureDiscussionComment
                    comment={comment}
                    key={index}
                    discussionId={this.props.discussionId}
                  />
                );
              })}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <p>Loading discussion...</p>
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const discussionId = ownProps.match.params.id;
  return {
    discussion: state.banner.singleDiscussion,
    comments: state.comment.scriptureDiscussionComments,
    groupId: state.group.selectedGroup.groupId,
    discussionId: discussionId,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    fetchSingleDiscussion(discussionId, groupId) {
      dispatch(fetchSingleDiscussion(discussionId, groupId));
    },
    fetchScriptureDiscussionComments(discussionId, groupId) {
      dispatch(fetchScriptureDiscussionComments(discussionId, groupId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScriptureDiscussionDetail);
