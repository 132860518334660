import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import createLogger from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './Reducers/rootReducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { reduxFirestore, getFirestore } from 'redux-firestore';
import { reactReduxFirebase, getFirebase } from 'react-redux-firebase';

import fbConfig from '../config/fbConfig';

const rrfConfig = {
  userProfile: 'users',
  attachAuthIsReady: true,
  useFirestoreForProfile: true,
};

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['group'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const createStoreWithFirebase = compose(
  applyMiddleware(
    thunkMiddleware.withExtraArgument({ getFirebase, getFirestore })
    // createLogger({ collapsed: true })
  ),
  reduxFirestore(fbConfig),
  reactReduxFirebase(fbConfig, rrfConfig)
)(createStore);

export const store = createStoreWithFirebase(persistedReducer);
export const persistor = persistStore(store);

// const store = createStore(
//   rootReducer,
//   compose(middleware, reduxFirestore(fbConfig), reactReduxFirebase(fbConfig))
// );

// export default store;
export * from './Reducers/groupReducer';
