/**
 * `components/index.js` exists simply as a 'central export' for our components.
 * This way, we can import all of our components from the same place, rather than
 * having to figure out which file they belong to!
 */
export { default as Navbar } from './Navbar/navbar';
export { default as Info } from './Info/Info';
export { default as InfoText } from './Info/InfoText';
export { default as LoginSignup } from './LoginSignup/LoginSignup';
export { default as Home } from './Home/Home';
export { default as MemberSignUp } from './LoginSignup/MemberSignUp';
export { default as LeaderLogin } from './LoginSignup/LeaderLogin';
export { default as MemberLogin } from './LoginSignup/MemberLogin';
export { default as PrayerDashboard } from './Prayers/PrayerDashboard';
export { default as PrayerDetail } from './Prayers/PrayerDetail';
export { default as AddPrayerForm } from './Prayers/AddPrayerForm';
export { default as MyPrayers } from './Prayers/MyPrayers';

export { default as LoadingPage } from './LoadingPage';

export { default as PhotoDashboard } from './Photos/PhotoDashboard';
export { default as ProfileDashboard } from './GroupProfile/ProfileDashboard';

export { default as CreateGroup } from './Group/CreateGroup';
export { default as JoinGroup } from './Group/JoinGroup';
export { default as MyGroups } from './Group/MyGroups';
export { default as LeaderDashboard } from './leaderDashboard/LeaderDashboard';
export { default as BannerDashboard } from './Banner/BannerDashboard';
export { default as BannerDiscussionDetail } from './Banner/BannerDiscussionDetail';

export { default as ScriptureDashboard } from './Scripture/ScriptureDashboard';
export { default as ScriptureDiscussionDetail } from './Scripture/ScriptureDiscussionDetail';

export { default as WorshipDashboard } from './Worship/WorshipDashboard';
export { default as WorshipDiscussionDetail } from './Worship/WorshipDiscussionDetail';

export { default as MessageDashboard } from './Message/MessageDashboard';
export { default as MessageDiscussionDetail } from './Message/MessageDiscussionDetail';

export { default as ArticleDashboard } from './Article/ArticleDashboard';
export { default as ArticleDiscussionDetail } from './Article/ArticleDiscussionDetail';

export { default as NotificationsSettingsBox } from './Notifications/NotificationsSettingsBox';
export { default as RecentlyDiscussed } from './RecentlyDiscussed/RecentlyDiscussed';

// export {default as UserHome} from './user-home'
// export {Login, Signup} from './auth-form'
