import React, { Component } from 'react';
import PrayerComment from './PrayerComment';
import { connect } from 'react-redux';
import CreateComment from '../CreateComment';
import { fetchPrayerComments } from '../../store/Reducers/commentReducer';
import { fetchSinglePrayer } from '../../store/Reducers/prayerReducer';

const prayerDetail = {
  display: 'flex',
  flexWrap: 'wrap',
};
const prayerText = {
  flex: '1',
  marginRight: '40px',
  marginTop: '40px',
  marginBottom: '40px',
  marginLeft: '40px',
};
const prayerComments = {
  flexDirection: 'column',
  flex: '1',
  marginRight: '40px',
  marginLeft: '40px',
  marginBottom: '40px',
};

class PrayerDetail extends Component {
  componentDidMount() {
    this.props.fetchPrayerComments(this.props.prayerId, this.props.groupId);
    this.props.fetchSinglePrayer(this.props.prayerId, this.props.groupId);
  }

  render() {
    const { prayer } = this.props;
    const comments = this.props.comments;
    if (prayer) {
      return (
        <div style={prayerDetail}>
          <div style={prayerText}>
            <div className={'box'}>
              <span className="title is-4">{prayer.title}</span>
              <p>posted by {prayer.displayAuthor}</p>
              <br />
              <p>{prayer.prayerText}</p>
              {/* <p>{moment(prayer.createdAt.toDate()).calendar()}</p> */}
            </div>
          </div>

          <div style={prayerComments}>
            <div className={'box'}>
              <CreateComment />
            </div>
            {comments &&
              comments.map((comment, index) => {
                return (
                  <PrayerComment
                    comment={comment}
                    key={index}
                    prayerId={this.props.prayerId}
                  />
                );
              })}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <p>Loading prayer...</p>
        </div>
      );
    }
  }
}
const mapStateToProps = (state, ownProps) => {
  const prayerId = ownProps.match.params.id;
  return {
    auth: state.firebase.auth,
    prayer: state.prayer.singlePrayer,
    comments: state.comment.prayerComments,
    groupId: state.group.selectedGroup.groupId,
    prayerId: prayerId,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    fetchSinglePrayer(prayerId, groupId) {
      dispatch(fetchSinglePrayer(prayerId, groupId));
    },
    fetchPrayerComments(prayerId, groupId) {
      dispatch(fetchPrayerComments(prayerId, groupId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrayerDetail);
