import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { signOut } from '../../store/Reducers/authReducer';
import { clearGroupState } from '../../store/Reducers/groupReducer';

const SignedInLinks = props => {
  const { group } = props;

  let isUserLeeaderOfSeelectedGroup = props.group.selectedGroup.isLeader;

  let toggleClass = () => {
    let burger = document.getElementsByClassName('navbar-burger');
    burger[0].classList.toggle('is-active');

    let navMenu = document.getElementsByClassName('navbar-menu');
    navMenu[0].classList.toggle('is-active');
  };

  return (
    <div>
      {/* The navbar will show these links after you log in */}
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <Link to="/home" className="navbar-item title is-5">
            Home
          </Link>

          <a
            role="button"
            className="navbar-burger burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
            onClick={toggleClass}
          >
            Menu
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link title is-5">Discussions</a>
            <div className="navbar-dropdown" onClick={toggleClass}>
              {group.selectedGroup.groupName && (
                <React.Fragment>
                  <Link to="/banner" className="navbar-item title is-5">
                    Banner
                  </Link>
                  <Link to="/prayers" className="navbar-item title is-5">
                    Prayer
                  </Link>

                  <Link to="/scripture" className="navbar-item title is-5">
                    Scripture
                  </Link>

                  <Link to="/worship" className="navbar-item title is-5">
                    Worship
                  </Link>
                  <Link to="/message" className="navbar-item title is-5">
                    Message
                  </Link>
                  <Link to="/article" className="navbar-item title is-5">
                    Articles
                  </Link>
                </React.Fragment>
              )}
            </div>
          </div>
          <Link
            to="/settings"
            className="navbar-item title is-5"
            onClick={toggleClass}
          >
            Notification Settings
          </Link>
          {isUserLeeaderOfSeelectedGroup && (
            <Link
              to="/leaderdashboard"
              className="navbar-item title is-5"
              onClick={toggleClass}
            >
              Leader Dashboard
            </Link>
          )}

          <div className="navbar-end">
            <a
              onClick={() => {
                props.signOut();
                props.clearGroupState();
              }}
              className="navbar-item title is-5"
            >
              Log Out
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    group: state.group,
    profile: state.firebase.profile,
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut()),
    clearGroupState: () => dispatch(clearGroupState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignedInLinks);
