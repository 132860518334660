import React from 'react';
import ArticleDiscussionSummary from './ArticleDiscussionSummary';
import { Link } from 'react-router-dom';

const ArticleDiscussionList = ({ discussions }) => {
  return (
    <div>
      {discussions &&
        discussions.map(discussion => {
          return (
            <Link
              to={'/article/' + discussion.discussionId}
              key={discussion.discussionId}
            >
              <ArticleDiscussionSummary discussion={discussion} />
            </Link>
          );
        })}
    </div>
  );
};

export default ArticleDiscussionList;
