import React from 'react';
import { connect } from 'react-redux';
import { makeMember, removeMember } from '../../store/Reducers/memberReducer';

const PotentialMemberCard = props => {
  const handleRemoveMemberClick = e => {
    e.preventDefault();
    props.removeMember(props.groupId, props.member.userId, props.groupName);
  };
  const handleMakeMemberClick = e => {
    e.preventDefault();
    props.makeMember(
      props.groupId,
      props.member.userId,
      props.group.selectedGroup.groupName
    );
  };

  let name = props.member.name;
  if (props)
    return (
      <div>
        <h1 className="title is-4">{name}</h1>
        <button className="button is-small" onClick={handleRemoveMemberClick}>
          Remove
        </button>
        <button className="button is-small" onClick={handleMakeMemberClick}>
          Make Member
        </button>
      </div>
    );
};
const mapStateToProps = state => {
  return {
    group: state.group,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    makeMember: (groupId, userId, groupName) =>
      dispatch(makeMember(groupId, userId, groupName)),
    removeMember: (groupId, userId, groupName) =>
      dispatch(removeMember(groupId, userId, groupName)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PotentialMemberCard);
