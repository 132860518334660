//this function will be used in the reducers
//it will be invoked whenever someone creates a document in each collection (not a comment)
export const createMail = (selectedGroup, sectionName) => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();
  const groupId = selectedGroup.groupId;
  const groupName = selectedGroup.groupName;
  const userToEmailArray = [];
  let formatedSectionName = sectionName[0].toUpperCase() + sectionName.slice(1);
  //build email parts
  let subjectString = groupName + ' ' + formatedSectionName + ' Notification';
  let htmlEmailBody =
    'A group member posted in the ' + sectionName + ' section';

  //grab members in the group that have their notification switch set to true
  firestore
    .collection('groups')
    .doc(groupId)
    .collection('members')
    .get()
    .then(members => {
      members.docs.forEach(groupMember => {
        let memberData = groupMember.data();
        if (memberData[sectionName + 'NotificationSwitch'] === true) {
          userToEmailArray.push(memberData.email);
        }
      });
    })
    .then(() => {
      //add email to mail collection
      firestore.collection('mail').add({
        to: 'smallgroupchatnotification@gmail.com',
        bcc: userToEmailArray,
        message: {
          subject: subjectString,
          text: 'This is the plaintext section of the email body.',
          html: htmlEmailBody,
        },
      });
    });
};

//INITIAL STATE-------------------------
const initState = {};
//REDUCER-------------------------------
const mailReducer = (state = initState, action) => {
  return state;
};
export default mailReducer;
