import React, { Component } from 'react';
import { connect } from 'react-redux';
import { default as MemberSignUp } from './MemberSignUp';
import { default as LeaderLogin } from './LeaderLogin';
import { default as MemberLogin } from './MemberLogin';

class LoginSignup extends Component {
  render() {
    return (
      <div>
        <MemberLogin />
        <LeaderLogin />
        <MemberSignUp />
      </div>
    );
  }
}
export default connect()(LoginSignup);
