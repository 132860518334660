import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  CreateGroup,
  JoinGroup,
  MyGroups,
  MyPrayers,
  RecentlyDiscussed,
  InfoText,
} from '../index';
import {
  fetchGroups,
  clearGroupState,
  checkGroupMembership,
} from '../../store/Reducers/groupReducer';
import history from '../../history';
import { resetSwitchState } from '../../store/Reducers/notificationReducer';

const homeContainer = {
  display: 'flex',
};
const myGroups = {
  flex: '1',
};
const joinCreate = {
  flexDirection: 'column',
  flex: '1',
};
class Home extends Component {
  async componentDidMount() {
    await this.props.checkGroupMembership();

    if (this.props.group.refreshNeeded) {
      await this.props.clearGroupState();

      if (Object.keys(this.props.group.myGroups).length === 0) {
        await this.props.fetchGroups();
        this.props.resetSwitchState();
      }
    }
  }

  render() {
    return (
      <div>
        <div className="columns homeContainer">
          <div className="column">
            <MyGroups />
            <RecentlyDiscussed />
            <MyPrayers />
          </div>
          <div className="column">
            <JoinGroup />
            <CreateGroup />
            <br></br>
            <InfoText />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    group: state.group,
    authError: state.auth.authError,
    profile: state.firebase.profile,
    prayer: state.prayer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchGroups: () => dispatch(fetchGroups()),
    clearGroupState: () => dispatch(clearGroupState()),
    checkGroupMembership: () => dispatch(checkGroupMembership()),
    resetSwitchState: () => dispatch(resetSwitchState()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
