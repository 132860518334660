import React, { Component } from 'react';

const infoSection = {
  flexDirection: 'column',
  // flex: '1',
  marginRight: '20px',
  marginLeft: '20px',
  // flexBasis: '200px',
  // textAlign: 'center',
  justifyContent: 'center',
};

export default class Info extends Component {
  render() {
    return (
      <div style={infoSection}>
        <h1 className={'title is-4'}>Welcome to Small Group Chat</h1>
        <p>
          This is a project I made to organize spiritual small groups I am a
          part of. I want this app to be a place where groups can have a safe
          place to dicuss and pray, and have an evolving dialog without the
          pressures of modern social media.
        </p>
        <br></br>

        <p>
          I aim to improve the quality and functionality of this app. If you
          find a part that isn't working right, or have an idea to improve,
          please feel free to email
        </p>
        <br></br>

        <strong>smallgroupchatapp@gmail.com</strong>
        <br></br>

        <a
          href="https://www.youtube.com/watch?v=L84YI9xSMYc"
          target="_blank"
          rel="noopener noreferrer"
        >
          Demo Video
        </a>
        <br></br>
        <br></br>
      </div>
    );
  }
}
