import React, { Component } from 'react';
import { connect } from 'react-redux';
import { signIn, resetPass } from '../../store/Reducers/authReducer';
import history from '../../history';

class MemberLogin extends Component {
  state = {
    email: '',
    password: '',
  };
  componentDidMount() {
    if (this.props.auth.uid) {
      history.push('/home');
    }
  }
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.signIn(this.state);
  };
  passwordResetClick = event => {
    event.preventDefault();
    this.props.resetPass(this.state.email);
  };
  render() {
    const { authError } = this.props;
    return (
      <div>
        <div className="box userLogin">
          <form onSubmit={this.handleSubmit}>
            <h3 className={'prayerReqTitle title is-3'}>Member Login</h3>

            <div className="input-field">
              <label htmlFor="email">Email</label>
              <input type="email" name="email" onChange={this.handleChange} />
            </div>
            <div className="input-field">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                onChange={this.handleChange}
              />
            </div>
            <div className="input-field">
              <button className="button">Login</button>
            </div>
          </form>

          {authError ? <p>{authError}</p> : null}
        </div>
        <div className="input-field userLogin">
          <p>for password reset...</p>
          <p>enter email above then click</p>
          <button className="button" onClick={this.passwordResetClick}>
            Password Reset
          </button>
          <p>check email for reset link</p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signIn: creds => dispatch(signIn(creds)),
    resetPass: email => dispatch(resetPass(email)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberLogin);
