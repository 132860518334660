import React, { Component } from 'react';
import { connect } from 'react-redux';
import { deletePrayerComment } from '../../store/Reducers/commentReducer';

const commentSummary = {
  display: 'flex',
};
const commentInfo = {
  flex: '4',
};
const commentDelete = {
  flex: '1',
};

class PrayerComment extends Component {
  deleteClick = (event, commentId, groupId, prayerId) => {
    event.preventDefault();
    this.props.deletePrayerComment(commentId, groupId, prayerId);
  };
  // if (props.comment.createdAt.seconds) {
  render() {
    return (
      <div className={'box'} style={commentSummary}>
        <div style={commentInfo}>
          <p>{this.props.comment.commentText}</p>
          <p>posted by {this.props.comment.displayAuthor}</p>
          {/* <p>{moment(props.comment.createdAt.toDate()).calendar()}</p> */}
          {/* </div> */}
          {/* <div style={commentDelete}> */}
          {this.props.auth.uid === this.props.comment.authorId && (
            <button
              onClick={event => {
                this.deleteClick(
                  event,
                  this.props.comment.commentId,
                  this.props.group.selectedGroup.groupId,
                  this.props.prayerId
                );
              }}
              className="button"
            >
              x
            </button>
          )}
        </div>
      </div>
    );
    // } else {
    //   return (
    //     <div>
    //       <p>{props.comment.commentText}</p>
    //       <p>posted by {props.comment.authorName}</p>
    //     </div>
    //   );
    // }
  }
}
const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    group: state.group,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deletePrayerComment: (commentId, groupId, prayerId) =>
      dispatch(deletePrayerComment(commentId, groupId, prayerId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrayerComment);
