import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddWorshipDiscussionForm from './AddWorshipDiscussionForm';
import WorshipDiscussionList from './WorshipDiscussionList';
import { fetchAllDiscussions } from '../../store/Reducers/worshipReducer';

const worshipBox = {
  marginRight: '20px',
  marginTop: '20px',
  marginBottom: '40px',
  marginLeft: '20px',
};

const worshipDashboardComp = {
  display: 'flex',
  flexWrap: 'wrap',
};

const worshipInfo = {
  flex: '1',
  marginBottom: '40px',
  flexBasis: '250px',
};
const worshipListSection = {
  flexDirection: 'column',
  flex: '1',
  marginRight: '20px',
  marginLeft: '20px',
  flexBasis: '200px',
  justifyContent: 'left',
};

const loadMoreWorshipDiscussionsButton = {
  marginTop: '20px',
  marginBottom: '20px',
  textAlign: 'center',
};

class WorshipDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = { limit: 5 };
  }

  componentDidMount() {
    this.props.fetchGroupWorshipDiscussions(
      this.props.group.selectedGroup.groupId,
      this.state.limit
    );
    this.state.limit += 5;
  }
  clickLoadMoreWorshipDiscussions = e => {
    e.preventDefault();
    this.props.fetchGroupWorshipDiscussions(
      this.props.group.selectedGroup.groupId,
      this.state.limit
    );
    this.state.limit += 5;
  };

  render() {
    const { discussions } = this.props.worship;
    return (
      <div className={'box'} style={worshipBox}>
        <h1 className={'title is-3'}>Worship Discussions</h1>
        <div style={worshipDashboardComp}>
          <div style={worshipInfo}>
            <AddWorshipDiscussionForm />
          </div>
          <div style={worshipListSection}>
            <WorshipDiscussionList discussions={discussions} />
            <div style={loadMoreWorshipDiscussionsButton}>
              <button
                className="button"
                onClick={this.clickLoadMoreWorshipDiscussions}
              >
                Load More
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    groupName: state.group.selectedGroup.groupName,
    group: state.group,
    worship: state.worship,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchGroupWorshipDiscussions: (groupId, limit) =>
      dispatch(fetchAllDiscussions(groupId, limit)),
  };
};

// export default compose(
//   firestoreConnect([{ collection: 'prayers', orderBy: ['createdAt', 'desc'] }]),
//   connect(mapStateToProps)
// )(PrayerDashboard);
export default connect(mapStateToProps, mapDispatchToProps)(WorshipDashboard);
