import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../history';

import { fetchGroups } from '../store/Reducers/groupReducer';
import { fetchMembers } from '../store/Reducers/memberReducer';

class LoadingPage extends Component {
  componentDidMount() {
    if (Object.keys(this.props.group.myGroups).length === 0) {
      this.props.fetchGroups();
    }
    history.push('/home');
  }

  render() {
    return (
      <div>
        <h1>Loading Your Groups!</h1>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    group: state.group,
    members: state.members,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchGroups: () => dispatch(fetchGroups()),
    fetchMembers: () => dispatch(fetchMembers()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoadingPage);
