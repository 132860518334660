import React from 'react';
import ScriptureDiscussionSummary from './ScriptureDiscussionSummary';
import { Link } from 'react-router-dom';

const ScriptureDiscussionList = ({ discussions }) => {
  return (
    <div>
      {discussions &&
        discussions.map(discussion => {
          return (
            <Link
              to={'/scripture/' + discussion.discussionId}
              key={discussion.discussionId}
            >
              <ScriptureDiscussionSummary discussion={discussion} />
            </Link>
          );
        })}
    </div>
  );
};

export default ScriptureDiscussionList;
