import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createGroup } from '../../store/Reducers/groupReducer';

const createGroupBox = {
  display: 'flex',
};

const createGroupForm = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
};

const infoFields = {
  flex: '1',
  alignSelf: 'center',
  width: '100%',
};

const submitFormButton = {
  flex: '1',
  textAlign: 'center',
  alignSelf: 'flex-end',
};

class CreateGroup extends Component {
  state = {
    groupName: '',
    password: '',
    // leaders: [],
    // members: [],
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    if (this.state.groupName && this.state.password) {
      this.props.addGroup(this.state);
      this.setState({
        groupName: '',
        password: '',
        // leaders: [],
        // members: [],
      });
      document.getElementById('groupNameCreateName').value = '';
      document.getElementById('groupNameCreatePassword').value = '';
    }
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className={'box'}>
          <h4 className="title is-4">Create A Group</h4>
          {/* <p>Hello Friends,</p>
          <p>Thank you for taking an interest in my app</p>
          <p>
            I regret to inform that I am suspending group creation until I can
            implement an improvement towards security.
          </p>
          <p>
            The app is secure as it stands, but after listening to a friend, God
            gave me an idea to make this app iron clad.
          </p>
          <p>
            I believe this improvement will take 2 weeks, but I'll ensure a
            definite roll out date of January 1st, 2020.
          </p>
          <p>
            Feel free to join and participate in my Demo Group. Groupname:
            "chapel" Password: "123456"
          </p>
          <p>
            If you have any questions, please email me at rdburke2891@gmail.com
          </p>
          <p>Love, Ryan</p> */}
          <div style={createGroupForm}>
            <div>
              <label>Group Name</label>
              <input
                className="input"
                placeholder="Enter Group Name"
                id="groupNameCreateName"
                type="text"
                name="groupName"
                onChange={this.handleChange}
              />
            </div>

            <div>
              <label>Member Password</label>
              <input
                className="input"
                type="password"
                id="groupNameCreatePassword"
                name="password"
                value={this.state.password}
                onChange={this.handleChange}
              />
            </div>
            <div style={submitFormButton}>
              <button className="button">Create Group</button>
            </div>
          </div>
        </div>
      </form>
    );
  }

  // render() {
  //   return (
  //     <form onSubmit={this.handleSubmit}>
  //       <div className={'box'}>
  //         <h4 className="title is-4">Create A Group</h4>
  //         <div style={createGroupForm}>
  //           <div>
  //             <label>Group Name</label>
  //             <input
  //               className="input"
  //               placeholder="Enter Group Name"
  //               id="groupNameCreateName"
  //               type="text"
  //               name="groupName"
  //               onChange={this.handleChange}
  //             />
  //           </div>

  //           <div>
  //             <label>Member Password</label>
  //             <input
  //               className="input"
  //               type="password"
  //               id="groupNameCreatePassword"
  //               name="password"
  //               value={this.state.password}
  //               onChange={this.handleChange}
  //             />
  //           </div>
  //           <div style={submitFormButton}>
  //             <button className="button">Create Group</button>
  //           </div>
  //         </div>
  //       </div>
  //     </form>
  //   );
  // }
}
const mapDispatchToProps = dispatch => {
  return {
    addGroup(group) {
      dispatch(createGroup(group));
    },
  };
};

export default connect(null, mapDispatchToProps)(CreateGroup);
