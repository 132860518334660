import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createDiscussion } from '../../store/Reducers/messageReducer';
import { createMail } from '../../store/Reducers/mailReducer';

const formInputs = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'left',
};
const DiscussionTitleArea = {
  flex: '1',
  width: '100%',
};

const discussionTextArea = {
  flex: '1',
  // alignItems: 'stretch',
  // flexBasis: '100%',
  width: '100%',
  // width: '400px',
};

class AddMessageDiscussionForm extends Component {
  state = {
    youtubeLink: '',
    title: '',
    displayAuthor: '',
    discussionText: '',
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    if (this.state.youtubeLink && this.state.title) {
      this.props.addDiscussion(this.state, this.props.group.selectedGroup);
      this.props.createMail(this.props.group.selectedGroup, 'message');

      document.getElementById('youtubeLink').value = '';
      document.getElementById('title').value = '';
      document.getElementById('displayAuthor').value = '';
      document.getElementById('discussionText').value = '';
      this.setState({
        youtubeLink: '',
        title: '',
        displayAuthor: '',
        discussionText: '',
      });
    }
  };
  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <h5 className="title is-4">Add A Message Discussion</h5>
          <div style={formInputs}>
            <div className="control">
              <label>Message Youtube Link</label>
              <input
                className={'input'}
                placeholder="Must be from Youtube"
                type="text"
                id="youtubeLink"
                name="youtubeLink"
                onChange={this.handleChange}
                style={DiscussionTitleArea}
              />
            </div>
            <div className="control">
              <label>Title</label>
              <input
                className={'input'}
                placeholder="Video Title"
                type="text"
                id="title"
                name="title"
                onChange={this.handleChange}
                style={DiscussionTitleArea}
              />
            </div>
            <div className="control">
              <label>Your Name</label>
              <input
                className={'input'}
                placeholder="Optional"
                type="text"
                id="displayAuthor"
                name="displayAuthor"
                onChange={this.handleChange}
                style={DiscussionTitleArea}
              />
            </div>
            <div className="control">
              <label>First Comment</label>
              <textarea
                className="textarea"
                placeholder="Start the discussion here..."
                type="text"
                id="discussionText"
                name="discussionText"
                onChange={this.handleChange}
                style={discussionTextArea}
              />
            </div>
          </div>

          <div>
            <button className="button">Add</button>
          </div>
        </form>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    group: state.group,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addDiscussion(discussion, group) {
      dispatch(createDiscussion(discussion, group));
    },
    createMail: (selectedGroup, sectionName) =>
      dispatch(createMail(selectedGroup, sectionName)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddMessageDiscussionForm);
