import React from 'react';
import { Link } from 'react-router-dom';

const SignedOutLinks = () => (
  <div>
    {/* The navbar will show these links before you log in */}
    <Link to="/login" className="title is-5">
      Login
    </Link>
    <Link to="/signup" className="title is-5">
      Sign Up
    </Link>
    <Link to="/info" className="title is-5">
      Info
    </Link>
  </div>
);
export default SignedOutLinks;
