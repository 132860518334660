import React, { Component } from 'react';
import { connect } from 'react-redux';
import LeaderCard from './LeaderCard';
import MemberCard from './MemberCard';
import { fetchGroups, selectGroup } from '../../store/Reducers/groupReducer';
import { fetchMembers, clearMembers } from '../../store/Reducers/memberReducer';
import { fetchMyPrayersInGroup } from '../../store/Reducers/prayerReducer';

class MemberList extends Component {
  state = {};
  componentDidMount() {
    this.props.clearMembers();
    this.props.fetchMembers(this.props.group.selectedGroup.groupId);
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  divClick = (event, name, groupId, leader) => {
    event.preventDefault();
  };
  returnApprovedMembers = groupMembers => {
    const approvedMembers = [];
    groupMembers.forEach(member => {
      if (
        member.approvedMember &&
        !member.isLeader &&
        member.approvedMember !== 'pending'
      ) {
        approvedMembers.push(member);
      }
    });
    return approvedMembers;
  };
  returnLeaders = groupMembers => {
    const leaders = [];
    groupMembers.forEach(member => {
      if (member.isLeader && member.approvedMember) {
        leaders.push(member);
      }
    });
    return leaders;
  };
  render() {
    const { groupMembers } = this.props.members;
    const approvedMembers = this.returnApprovedMembers(groupMembers);
    const leaders = this.returnLeaders(groupMembers);
    const groupId = this.props.group.selectedGroup.groupId;
    const groupName = this.props.group.selectedGroup.groupName;

    return (
      <div className={'myGroups'}>
        <div className={'box'}>
          <h4 className="title is-3">Group Members</h4>
          {leaders.map(member => {
            return (
              <div
                onClick={event => {
                  this.divClick(event);
                }}
                key={member.userId}
              >
                <LeaderCard
                  member={member}
                  groupId={groupId}
                  groupName={groupName}
                />
              </div>
            );
          })}
          {approvedMembers.map(member => {
            return (
              <div
                onClick={event => {
                  this.divClick(event);
                }}
                key={member.userId}
              >
                <MemberCard
                  member={member}
                  groupId={groupId}
                  groupName={groupName}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    group: state.group,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    members: state.members,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchMembers: groupId => dispatch(fetchMembers(groupId)),
    clearMembers: () => dispatch(clearMembers()),

    selectGroup: (groupName, id, leaders) =>
      dispatch(selectGroup(groupName, id, leaders)),
    fetchMyPrayersInGroup: (groupId, limit) =>
      dispatch(fetchMyPrayersInGroup(groupId, limit)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberList);
