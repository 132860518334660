import React, { Component } from 'react';
import { connect } from 'react-redux';
import { deleteDiscussion } from '../../store/Reducers/messageReducer';

const MessageDiscussionSummaryComp = {
  display: 'flex',
};

const DiscussionInfo = {
  flex: '4',
};
const DiscussionDelete = {
  flex: '1',
};

class MessageDiscussionSummary extends Component {
  deleteClick = (event, discussionId, groupId) => {
    event.preventDefault();
    this.props.deleteDiscussion(discussionId, groupId);
  };
  render() {
    return (
      <div>
        <div className={'box'}>
          <div style={MessageDiscussionSummaryComp}>
            <div style={DiscussionInfo}>
              <span className="title is-4">{this.props.discussion.title}</span>
              <h1 className="title is-6">
                posted by {this.props.discussion.displayAuthor}
              </h1>
            </div>
            <div style={DiscussionDelete}>
              {this.props.auth.uid === this.props.discussion.authorId && (
                <button
                  onClick={event => {
                    this.deleteClick(
                      event,
                      this.props.discussion.discussionId,
                      this.props.group.selectedGroup.groupId
                    );
                  }}
                  className="button"
                >
                  x
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    group: state.group,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteDiscussion: (discussionId, groupId) =>
      dispatch(deleteDiscussion(discussionId, groupId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageDiscussionSummary);
