import React from 'react';
import MessageDiscussionSummary from './MessageDiscussionSummary';
import { Link } from 'react-router-dom';

const MessageDiscussionList = ({ discussions }) => {
  return (
    <div>
      {discussions &&
        discussions.map(discussion => {
          return (
            <Link
              to={'/message/' + discussion.discussionId}
              key={discussion.discussionId}
            >
              <MessageDiscussionSummary discussion={discussion} />
            </Link>
          );
        })}
    </div>
  );
};

export default MessageDiscussionList;
