import React from 'react';
import WorshipDiscussionSummary from './WorshipDiscussionSummary';
import { Link } from 'react-router-dom';

const WorshipDiscussionList = ({ discussions }) => {
  return (
    <div>
      {discussions &&
        discussions.map(discussion => {
          return (
            <Link
              to={'/worship/' + discussion.discussionId}
              key={discussion.discussionId}
            >
              <WorshipDiscussionSummary discussion={discussion} />
            </Link>
          );
        })}
    </div>
  );
};

export default WorshipDiscussionList;
