import React from 'react';
import { connect } from 'react-redux';
import { leaveGroup, fetchGroups } from '../../store/Reducers/groupReducer';

const MyGroupCard = props => {
  const handleClick = e => {
    e.preventDefault();
    props.leaveGroup(props.group);
  };
  const { group } = props;

  let groupName = '';

  if (group.approvedMember && group.approvedMember !== 'pending')
    groupName = group.groupName;
  else if (group.approvedMember === 'pending')
    groupName = group.groupName + ' - membership pending';

  return (
    <div>
      <h1 className="title is-4">{groupName}</h1>
      <button className="button is-small" onClick={handleClick}>
        leave
      </button>
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    leaveGroup: group => dispatch(leaveGroup(group)),
    fetchGroups: () => dispatch(fetchGroups()),
  };
};

export default connect(null, mapDispatchToProps)(MyGroupCard);
