import React, { Component } from 'react';
import { connect } from 'react-redux';
import { deletePrayer } from '../../store/Reducers/prayerReducer';

const PrayerListSummary = {
  display: 'flex',
};

const PrayerInfo = {
  flex: '4',
};
const PrayerDelete = {
  flex: '1',
};

class PrayerSummary extends Component {
  deleteClick = (event, prayerId, groupId) => {
    event.preventDefault();
    this.props.deletePrayer(prayerId, groupId);
  };
  render() {
    return (
      <div>
        <div className={'box'}>
          <div style={PrayerListSummary}>
            <div style={PrayerInfo}>
              <span className="title is-4">{this.props.prayer.title}</span>
              <h1 className="title is-6">
                posted by {this.props.prayer.displayAuthor}
              </h1>
            </div>
            <div style={PrayerDelete}>
              {this.props.auth.uid === this.props.prayer.authorId && (
                <button
                  onClick={event => {
                    this.deleteClick(
                      event,
                      this.props.prayer.prayerId,
                      this.props.group.selectedGroup.groupId
                    );
                  }}
                  className="button"
                >
                  x
                </button>
              )}
            </div>
            {/* <p>{moment(prayer.createdAt.toDate()).calendar()}</p> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    group: state.group,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deletePrayer: (prayerId, groupId) =>
      dispatch(deletePrayer(prayerId, groupId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrayerSummary);
