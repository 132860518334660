import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateBannerInfo } from '../../store/Reducers/bannerReducer';

// const joinGroupForm = {
//   display: 'flex',
//   // marginLeft: '20px',
// };

const updateBannerForm = {
  display: 'flex',
  // flexWrap: 'wrap',
  justifyContent: 'center',
  flexDirection: 'column',
};

// const infoFields = {
//   flex: '1',
//   alignSelf: 'center',
//   width: '100%',
// };

const submitFormButton = {
  flex: '1',
  textAlign: 'center',
  // alignSelf: 'flex-end',
};

class UpdateBannerForm extends Component {
  state = {
    eventTitle: '',
    eventDate: '',
    eventAddress: '',
    eventInfo: '',
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.updateBannerInfo(this.state, this.props.selectedGroupId);
    document.getElementById('eventTitle').value = '';
    document.getElementById('eventDate').value = '';
    document.getElementById('eventAddress').value = '';
    document.getElementById('eventInfo').value = '';
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className={'box'}>
          <h4 className="title is-4">Update Banner</h4>
          <div style={updateBannerForm}>
            <div>
              <label>Event Title</label>
              <input
                id="eventTitle"
                placeholder="Event Title"
                className="input"
                type="text"
                name="eventTitle"
                onChange={this.handleChange}
              />
            </div>

            <div>
              <label>Event Date/Time</label>
              <input
                id="eventDate"
                placeholder="When is the event?"
                className="input"
                type="text"
                name="eventDate"
                onChange={this.handleChange}
              />
            </div>
            <div>
              <label>Event Address</label>
              <input
                id="eventAddress"
                placeholder="Where is the event?"
                className="input"
                type="text"
                name="eventAddress"
                onChange={this.handleChange}
              />
            </div>
            <div>
              <label>Event Info</label>
              <input
                id="eventInfo"
                placeholder="Any additional info for group members?"
                className="input"
                type="text"
                name="eventInfo"
                onChange={this.handleChange}
              />
            </div>

            <div style={submitFormButton}>
              <button className="button">Update Banner</button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}
const mapStateToProps = state => {
  return {
    selectedGroupId: state.group.selectedGroup.groupId,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateBannerInfo: (info, group) => dispatch(updateBannerInfo(info, group)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateBannerForm);
