import { getFirestore } from 'redux-firestore';

//ACTION TYPES--------------------------
const LOAD_BANNER_DISCUSSION_COMMENTS = 'LOAD_BANNER_DISCUSSION_COMMENTS';
const ADD_BANNER_DISCUSSION_COMMENT = 'ADD_BANNER_DISCUSSION_COMMENT';
const REMOVE_BANNER_DISCUSSION_COMMENT = 'REMOVE_BANNER_DISCUSSION_COMMENT';

//=============================================================================

const LOAD_PRAYER_COMMENTS = 'LOAD_PRAYER_COMMENTS';
const ADD_PRAYER_COMMENT = 'ADD_PRAYER_COMMENT';
const REMOVE_PRAYER_COMMENT = 'REMOVE_PRAYER_COMMENT';

//=============================================================================

const LOAD_SCRIPTURE_DISCUSSION_COMMENTS = 'LOAD_SCRIPTURE_DISCUSSION_COMMENTS';
const ADD_SCRIPTURE_DISCUSSION_COMMENT = 'ADD_SCRIPTURE_DISCUSSION_COMMENT';
const REMOVE_SCRIPTURE_DISCUSSION_COMMENT =
  'REMOVE_SCRIPTURE_DISCUSSION_COMMENT';

//=============================================================================

const LOAD_WORSHIP_DISCUSSION_COMMENTS = 'LOAD_WORSHIP_DISCUSSION_COMMENTS';
const ADD_WORSHIP_DISCUSSION_COMMENT = 'ADD_WORSHIP_DISCUSSION_COMMENT';
const REMOVE_WORSHIP_DISCUSSION_COMMENT = 'REMOVE_WORSHIP_DISCUSSION_COMMENT';

//=============================================================================

const LOAD_MESSAGE_DISCUSSION_COMMENTS = 'LOAD_MESSAGE_DISCUSSION_COMMENTS';
const ADD_MESSAGE_DISCUSSION_COMMENT = 'ADD_MESSAGE_DISCUSSION_COMMENT';
const REMOVE_MESSAGE_DISCUSSION_COMMENT = 'REMOVE_MESSAGE_DISCUSSION_COMMENT';

//=============================================================================

const LOAD_ARTICLE_DISCUSSION_COMMENTS = 'LOAD_ARTICLE_DISCUSSION_COMMENTS';
const ADD_ARTICLE_DISCUSSION_COMMENT = 'ADD_ARTICLE_DISCUSSION_COMMENT';
const REMOVE_ARTICLE_DISCUSSION_COMMENT = 'REMOVE_ARTICLE_DISCUSSION_COMMENT';

//ACTION CREATORS-----------------------
const loadBannerDiscussionComments = comments => ({
  type: LOAD_BANNER_DISCUSSION_COMMENTS,
  comments,
});
const addBannerDiscussionComment = comment => ({
  type: ADD_BANNER_DISCUSSION_COMMENT,
  comment,
});
const removeBannerDiscussionComment = commentId => ({
  type: REMOVE_BANNER_DISCUSSION_COMMENT,
  commentId,
});
//=============================================================================

const loadPrayerComments = comments => ({
  type: LOAD_PRAYER_COMMENTS,
  comments,
});
const addPrayerComment = comment => ({ type: ADD_PRAYER_COMMENT, comment });
const removePrayerComment = commentId => ({
  type: REMOVE_PRAYER_COMMENT,
  commentId,
});

//=============================================================================

const loadScriptureDiscussionComments = comments => ({
  type: LOAD_SCRIPTURE_DISCUSSION_COMMENTS,
  comments,
});
const addScriptureDiscussionComment = comment => ({
  type: ADD_SCRIPTURE_DISCUSSION_COMMENT,
  comment,
});
const removeScriptureDiscussionComment = commentId => ({
  type: REMOVE_SCRIPTURE_DISCUSSION_COMMENT,
  commentId,
});

//=============================================================================

const loadWorshipDiscussionComments = comments => ({
  type: LOAD_WORSHIP_DISCUSSION_COMMENTS,
  comments,
});
const addWorshipDiscussionComment = comment => ({
  type: ADD_WORSHIP_DISCUSSION_COMMENT,
  comment,
});
const removeWorshipDiscussionComment = commentId => ({
  type: REMOVE_WORSHIP_DISCUSSION_COMMENT,
  commentId,
});

//=============================================================================

const loadMessageDiscussionComments = comments => ({
  type: LOAD_MESSAGE_DISCUSSION_COMMENTS,
  comments,
});
const addMessageDiscussionComment = comment => ({
  type: ADD_MESSAGE_DISCUSSION_COMMENT,
  comment,
});
const removeMessageDiscussionComment = commentId => ({
  type: REMOVE_MESSAGE_DISCUSSION_COMMENT,
  commentId,
});

//=============================================================================

const loadArticleDiscussionComments = comments => ({
  type: LOAD_ARTICLE_DISCUSSION_COMMENTS,
  comments,
});
const addArticleDiscussionComment = comment => ({
  type: ADD_ARTICLE_DISCUSSION_COMMENT,
  comment,
});
const removeArticleDiscussionComment = commentId => ({
  type: REMOVE_ARTICLE_DISCUSSION_COMMENT,
  commentId,
});

const updateRecentlyDiscussed = (
  groupId,
  discussionId,
  discussionTitle,
  discussionType
) => {
  //============================================================
  //============================================================
  //UPDATE RECENTLY DISCUSSED =====================================
  const firestore = getFirestore();

  firestore
    .collection('groups')
    .doc(groupId)
    .collection('recentlyDiscussed')
    .doc('mostRecent')
    .get()
    .then(mostRecentResp => {
      const mostRecentArray = mostRecentResp.data().discussions;

      if (mostRecentArray.length === 5) {
        mostRecentArray.pop();
      }

      const filteredArray = mostRecentArray.filter(discussion => {
        return discussion.id !== discussionId;
      });
      filteredArray.unshift({
        id: discussionId,
        type: discussionType,
        discussionTitle: discussionTitle,
      });

      firestore
        .collection('groups')
        .doc(groupId)
        .collection('recentlyDiscussed')
        .doc('mostRecent')
        .update({ discussions: filteredArray });
    });
  //============================================================
  //============================================================
  //============================================================
};

//THUNK CREATORS------------------------

//=============================================================================
//=============================================================================
//BANNER DISCUSSON COMMENTS
//=============================================================================
//=============================================================================

export const fetchBannerDiscussionComments = (discussionId, groupId) => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();

  firestore
    .collection('groups')
    .doc(groupId)
    .collection('bannerDiscussions')
    .doc(discussionId)
    .collection('comments')
    .get()
    .then(comments => {
      let returnArray = [];
      comments.docs.forEach(comment => {
        returnArray.push({ ...comment.data(), commentId: comment.id });
      });
      dispatch(
        loadBannerDiscussionComments(
          returnArray.sort((a, b) => {
            return b.createdAt.seconds - a.createdAt.seconds;
          })
        )
      );
    });
};

export const createBannerDiscussionComment = (
  discussionId,
  groupId,
  authorId,
  commentText,
  displayAuthor,
  discussionTitle
) => (dispatch, getState, { getFirebase, getFirestore }) => {
  const firestore = getFirestore();
  const profile = getState().firebase.profile;
  const date = new Date();
  firestore
    .collection('groups')
    .doc(groupId)
    .collection('bannerDiscussions')
    .doc(discussionId)
    .collection('comments')
    .add({
      commentText: commentText,
      authorName: profile.name,
      createdAt: date,
      authorId: authorId,
      displayAuthor: displayAuthor,
    })
    .then(resp => {
      dispatch(
        addBannerDiscussionComment({
          commentId: resp.id,
          commentText: commentText,
          authorName: profile.name,
          createdAt: date,
          authorId: authorId,
          displayAuthor: displayAuthor,
        })
      );
      //UPDATE RECENTLY DISCUSSED =====================================
      updateRecentlyDiscussed(groupId, discussionId, discussionTitle, 'banner');
      //===============================================================
    });
};
export const deleteBannerDiscussionComment = (
  commentId,
  groupId,
  discussionId
) => (dispatch, getState, { getFirebase, getFirestore }) => {
  const firestore = getFirestore();

  firestore
    .collection('groups')
    .doc(groupId)
    .collection('bannerDiscussions')
    .doc(discussionId)
    .collection('comments')
    .doc(commentId)
    .delete()
    .then(() => {
      dispatch(removeBannerDiscussionComment(commentId));
    });
};

//=============================================================================
//=============================================================================
//PRAYER COMMENTS
//=============================================================================
//=============================================================================

export const fetchPrayerComments = (prayerId, groupId) => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();

  firestore
    .collection('groups')
    .doc(groupId)
    .collection('prayers')
    .doc(prayerId)
    .collection('comments')
    .get()
    .then(comments => {
      let returnArray = [];
      comments.docs.forEach(comment => {
        returnArray.push({ ...comment.data(), commentId: comment.id });
      });
      dispatch(
        loadPrayerComments(
          returnArray.sort((a, b) => {
            return b.createdAt.seconds - a.createdAt.seconds;
          })
        )
      );
    });
};

export const createPrayerComment = (
  prayerId,
  groupId,
  authorId,
  commentText,
  displayAuthor,
  prayerTitle
) => (dispatch, getState, { getFirebase, getFirestore }) => {
  const firestore = getFirestore();
  const profile = getState().firebase.profile;
  const date = new Date();

  firestore
    .collection('groups')
    .doc(groupId)
    .collection('prayers')
    .doc(prayerId)
    .collection('comments')
    .add({
      commentText: commentText,
      authorName: profile.name,
      createdAt: date,
      authorId: authorId,
      displayAuthor: displayAuthor,
    })
    .then(resp => {
      dispatch(
        addPrayerComment({
          commentId: resp.id,
          commentText: commentText,
          authorName: profile.name,
          createdAt: date,
          authorId: authorId,
          displayAuthor: displayAuthor,
        })
      );
      //UPDATE RECENTLY DISCUSSED =====================================
      updateRecentlyDiscussed(groupId, prayerId, prayerTitle, 'prayers');
      //===============================================================
    });
};

export const deletePrayerComment = (commentId, groupId, prayerId) => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();

  firestore
    .collection('groups')
    .doc(groupId)
    .collection('prayers')
    .doc(prayerId)
    .collection('comments')
    .doc(commentId)
    .delete()
    .then(() => {
      dispatch(removePrayerComment(commentId));
    });
};

//=============================================================================
//=============================================================================
//SCRIPTURE DISCUSSON COMMENTS
//=============================================================================
//=============================================================================

export const fetchScriptureDiscussionComments = (discussionId, groupId) => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();

  firestore
    .collection('groups')
    .doc(groupId)
    .collection('scriptureDiscussions')
    .doc(discussionId)
    .collection('comments')
    .get()
    .then(comments => {
      let returnArray = [];
      comments.docs.forEach(comment => {
        returnArray.push({ ...comment.data(), commentId: comment.id });
      });
      dispatch(
        loadScriptureDiscussionComments(
          returnArray.sort((a, b) => {
            return b.createdAt.seconds - a.createdAt.seconds;
          })
        )
      );
    });
};

export const createScriptureDiscussionComment = (
  discussionId,
  groupId,
  authorId,
  commentText,
  displayAuthor,
  discussionTitle
) => (dispatch, getState, { getFirebase, getFirestore }) => {
  const firestore = getFirestore();
  const profile = getState().firebase.profile;
  const date = new Date();
  firestore
    .collection('groups')
    .doc(groupId)
    .collection('scriptureDiscussions')
    .doc(discussionId)
    .collection('comments')
    .add({
      commentText: commentText,
      authorName: profile.name,
      createdAt: date,
      authorId: authorId,
      displayAuthor: displayAuthor,
    })
    .then(resp => {
      dispatch(
        addScriptureDiscussionComment({
          commentId: resp.id,
          commentText: commentText,
          authorName: profile.name,
          createdAt: date,
          authorId: authorId,
          displayAuthor: displayAuthor,
        })
      );
      //UPDATE RECENTLY DISCUSSED =====================================
      updateRecentlyDiscussed(
        groupId,
        discussionId,
        discussionTitle,
        'scripture'
      );
      //===============================================================
    });
};
export const deleteScriptureDiscussionComment = (
  commentId,
  groupId,
  discussionId
) => (dispatch, getState, { getFirebase, getFirestore }) => {
  const firestore = getFirestore();

  firestore
    .collection('groups')
    .doc(groupId)
    .collection('scriptureDiscussions')
    .doc(discussionId)
    .collection('comments')
    .doc(commentId)
    .delete()
    .then(() => {
      dispatch(removeScriptureDiscussionComment(commentId));
    });
};

//=============================================================================
//=============================================================================
//WORSHIP DISCUSSON COMMENTS
//=============================================================================
//=============================================================================

export const fetchWorshipDiscussionComments = (discussionId, groupId) => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();

  firestore
    .collection('groups')
    .doc(groupId)
    .collection('worshipDiscussions')
    .doc(discussionId)
    .collection('comments')
    .get()
    .then(comments => {
      let returnArray = [];
      comments.docs.forEach(comment => {
        returnArray.push({ ...comment.data(), commentId: comment.id });
      });
      dispatch(
        loadWorshipDiscussionComments(
          returnArray.sort((a, b) => {
            return b.createdAt.seconds - a.createdAt.seconds;
          })
        )
      );
    });
};

export const createWorshipDiscussionComment = (
  discussionId,
  groupId,
  authorId,
  commentText,
  displayAuthor,
  discussionTitle
) => (dispatch, getState, { getFirebase, getFirestore }) => {
  const firestore = getFirestore();
  const profile = getState().firebase.profile;
  const date = new Date();
  firestore
    .collection('groups')
    .doc(groupId)
    .collection('worshipDiscussions')
    .doc(discussionId)
    .collection('comments')
    .add({
      commentText: commentText,
      authorName: profile.name,
      createdAt: date,
      authorId: authorId,
      displayAuthor: displayAuthor,
    })
    .then(resp => {
      dispatch(
        addWorshipDiscussionComment({
          commentId: resp.id,
          commentText: commentText,
          authorName: profile.name,
          createdAt: date,
          authorId: authorId,
          displayAuthor: displayAuthor,
        })
      );
      //UPDATE RECENTLY DISCUSSED =====================================
      updateRecentlyDiscussed(
        groupId,
        discussionId,
        discussionTitle,
        'worship'
      );
      //===============================================================
    });
};
export const deleteWorshipDiscussionComment = (
  commentId,
  groupId,
  discussionId
) => (dispatch, getState, { getFirebase, getFirestore }) => {
  const firestore = getFirestore();

  firestore
    .collection('groups')
    .doc(groupId)
    .collection('worshipDiscussions')
    .doc(discussionId)
    .collection('comments')
    .doc(commentId)
    .delete()
    .then(() => {
      dispatch(removeWorshipDiscussionComment(commentId));
    });
};

//=============================================================================
//=============================================================================
//MESSAGE DISCUSSON COMMENTS
//=============================================================================
//=============================================================================

export const fetchMessageDiscussionComments = (discussionId, groupId) => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();

  firestore
    .collection('groups')
    .doc(groupId)
    .collection('messageDiscussions')
    .doc(discussionId)
    .collection('comments')
    .get()
    .then(comments => {
      let returnArray = [];
      comments.docs.forEach(comment => {
        returnArray.push({ ...comment.data(), commentId: comment.id });
      });
      dispatch(
        loadMessageDiscussionComments(
          returnArray.sort((a, b) => {
            return b.createdAt.seconds - a.createdAt.seconds;
          })
        )
      );
    });
};

export const createMessageDiscussionComment = (
  discussionId,
  groupId,
  authorId,
  commentText,
  displayAuthor,
  discussionTitle
) => (dispatch, getState, { getFirebase, getFirestore }) => {
  const firestore = getFirestore();
  const profile = getState().firebase.profile;
  const date = new Date();
  firestore
    .collection('groups')
    .doc(groupId)
    .collection('messageDiscussions')
    .doc(discussionId)
    .collection('comments')
    .add({
      commentText: commentText,
      authorName: profile.name,
      createdAt: date,
      authorId: authorId,
      displayAuthor: displayAuthor,
    })
    .then(resp => {
      dispatch(
        addMessageDiscussionComment({
          commentId: resp.id,
          commentText: commentText,
          authorName: profile.name,
          createdAt: date,
          authorId: authorId,
          displayAuthor: displayAuthor,
        })
      );
      //UPDATE RECENTLY DISCUSSED =====================================
      updateRecentlyDiscussed(
        groupId,
        discussionId,
        discussionTitle,
        'message'
      );
      //===============================================================
    });
};
export const deleteMessageDiscussionComment = (
  commentId,
  groupId,
  discussionId
) => (dispatch, getState, { getFirebase, getFirestore }) => {
  const firestore = getFirestore();

  firestore
    .collection('groups')
    .doc(groupId)
    .collection('messageDiscussions')
    .doc(discussionId)
    .collection('comments')
    .doc(commentId)
    .delete()
    .then(() => {
      dispatch(removeMessageDiscussionComment(commentId));
    });
};

//=============================================================================
//=============================================================================
//ARTICLE DISCUSSON COMMENTS
//=============================================================================
//=============================================================================

export const fetchArticleDiscussionComments = (discussionId, groupId) => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();

  firestore
    .collection('groups')
    .doc(groupId)
    .collection('articleDiscussions')
    .doc(discussionId)
    .collection('comments')
    .get()
    .then(comments => {
      let returnArray = [];
      comments.docs.forEach(comment => {
        returnArray.push({ ...comment.data(), commentId: comment.id });
      });
      dispatch(
        loadArticleDiscussionComments(
          returnArray.sort((a, b) => {
            return b.createdAt.seconds - a.createdAt.seconds;
          })
        )
      );
    });
};

export const createArticleDiscussionComment = (
  discussionId,
  groupId,
  authorId,
  commentText,
  displayAuthor,
  discussionTitle
) => (dispatch, getState, { getFirebase, getFirestore }) => {
  const firestore = getFirestore();
  const profile = getState().firebase.profile;
  const date = new Date();
  firestore
    .collection('groups')
    .doc(groupId)
    .collection('articleDiscussions')
    .doc(discussionId)
    .collection('comments')
    .add({
      commentText: commentText,
      authorName: profile.name,
      createdAt: date,
      authorId: authorId,
      displayAuthor: displayAuthor,
    })
    .then(resp => {
      dispatch(
        addArticleDiscussionComment({
          commentId: resp.id,
          commentText: commentText,
          authorName: profile.name,
          createdAt: date,
          authorId: authorId,
          displayAuthor: displayAuthor,
        })
      );
      //UPDATE RECENTLY DISCUSSED =====================================
      updateRecentlyDiscussed(
        groupId,
        discussionId,
        discussionTitle,
        'article'
      );
      //===============================================================
    });
};
export const deleteArticleDiscussionComment = (
  commentId,
  groupId,
  discussionId
) => (dispatch, getState, { getFirebase, getFirestore }) => {
  const firestore = getFirestore();

  firestore
    .collection('groups')
    .doc(groupId)
    .collection('articleDiscussions')
    .doc(discussionId)
    .collection('comments')
    .doc(commentId)
    .delete()
    .then(() => {
      dispatch(removeArticleDiscussionComment(commentId));
    });
};

//=============================================================================
//=============================================================================

//INITIAL STATE-------------------------
const initState = {
  bannerDiscussionComments: [],
  prayerComments: [],
  scriptureDiscussionComments: [],
  worshipDiscussionComments: [],
  messageDiscussionComments: [],
  articleDiscussionComments: [],
};
//REDUCER-------------------------------
// eslint-disable-next-line complexity
const commentReducer = (state = initState, action) => {
  switch (action.type) {
    //=============================================================================
    case LOAD_BANNER_DISCUSSION_COMMENTS:
      return {
        ...state,
        bannerDiscussionComments: action.comments,
      };
    case ADD_BANNER_DISCUSSION_COMMENT:
      return {
        ...state,
        bannerDiscussionComments: [
          action.comment,
          ...state.bannerDiscussionComments,
        ],
      };
    case REMOVE_BANNER_DISCUSSION_COMMENT:
      return {
        ...state,
        bannerDiscussionComments: state.bannerDiscussionComments.filter(
          comment => {
            return comment.commentId !== action.commentId;
          }
        ),
      };
    //=============================================================================

    case LOAD_PRAYER_COMMENTS:
      return {
        ...state,
        prayerComments: action.comments,
      };
    case ADD_PRAYER_COMMENT:
      return {
        ...state,
        prayerComments: [action.comment, ...state.prayerComments],
      };
    case REMOVE_PRAYER_COMMENT:
      return {
        ...state,
        prayerComments: state.prayerComments.filter(comment => {
          return comment.commentId !== action.commentId;
        }),
      };
    //=============================================================================

    case LOAD_SCRIPTURE_DISCUSSION_COMMENTS:
      return {
        ...state,
        scriptureDiscussionComments: action.comments,
      };
    case ADD_SCRIPTURE_DISCUSSION_COMMENT:
      return {
        ...state,
        scriptureDiscussionComments: [
          action.comment,
          ...state.scriptureDiscussionComments,
        ],
      };
    case REMOVE_SCRIPTURE_DISCUSSION_COMMENT:
      return {
        ...state,
        scriptureDiscussionComments: state.scriptureDiscussionComments.filter(
          comment => {
            return comment.commentId !== action.commentId;
          }
        ),
      };
    //=============================================================================

    case LOAD_WORSHIP_DISCUSSION_COMMENTS:
      return {
        ...state,
        worshipDiscussionComments: action.comments,
      };
    case ADD_WORSHIP_DISCUSSION_COMMENT:
      return {
        ...state,
        worshipDiscussionComments: [
          action.comment,
          ...state.worshipDiscussionComments,
        ],
      };
    case REMOVE_WORSHIP_DISCUSSION_COMMENT:
      return {
        ...state,
        worshipDiscussionComments: state.worshipDiscussionComments.filter(
          comment => {
            return comment.commentId !== action.commentId;
          }
        ),
      };
    //=============================================================================

    case LOAD_MESSAGE_DISCUSSION_COMMENTS:
      return {
        ...state,
        messageDiscussionComments: action.comments,
      };
    case ADD_MESSAGE_DISCUSSION_COMMENT:
      return {
        ...state,
        messageDiscussionComments: [
          action.comment,
          ...state.messageDiscussionComments,
        ],
      };
    case REMOVE_MESSAGE_DISCUSSION_COMMENT:
      return {
        ...state,
        messageDiscussionComments: state.messageDiscussionComments.filter(
          comment => {
            return comment.commentId !== action.commentId;
          }
        ),
      };
    //=============================================================================

    case LOAD_ARTICLE_DISCUSSION_COMMENTS:
      return {
        ...state,
        articleDiscussionComments: action.comments,
      };
    case ADD_ARTICLE_DISCUSSION_COMMENT:
      return {
        ...state,
        articleDiscussionComments: [
          action.comment,
          ...state.articleDiscussionComments,
        ],
      };
    case REMOVE_ARTICLE_DISCUSSION_COMMENT:
      return {
        ...state,
        articleDiscussionComments: state.articleDiscussionComments.filter(
          comment => {
            return comment.commentId !== action.commentId;
          }
        ),
      };
    default:
      return state;
  }
};
export default commentReducer;
