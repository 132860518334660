//
//FIREBASE SECRETS
//--------------------------------------------------

process.env.FIREBASE_apiKey = 'AIzaSyDW5WQq6hNedJOyi0FL-tn2RZ0hZG1OX6Y';
process.env.FIREBASE_authDomain = 'home-group-app.firebaseapp.com';
process.env.FIREBASE_databaseURL = 'https://home-group-app.firebaseio.com';
process.env.FIREBASE_projectId = 'home-group-app';
process.env.FIREBASE_storageBucket = 'home-group-app.appspot.com';
process.env.FIREBASE_messagingSenderId = '200998020458';

//--------------------------------------------------

const firebaseSecrets = {
  apiKey: 'AIzaSyDW5WQq6hNedJOyi0FL-tn2RZ0hZG1OX6Y',
  authDomain: 'home-group-app.firebaseapp.com',
  databaseURL: 'https://home-group-app.firebaseio.com',
  projectId: 'home-group-app',
  storageBucket: 'home-group-app.appspot.com',
  messagingSenderId: '200998020458',
};

module.exports = {
  firebaseSecrets,
};

//password for mailgun
//28b1fae0f94f78792d52de3faa225a5e-c322068c-11ba81a3
