import React, { Component } from 'react';
import { connect } from 'react-redux';
import MyPrayerCard from './MyPrayerCard';
import { fetchGroups } from '../../store/Reducers/groupReducer';
import {
  fetchMyPrayersInGroup,
  fetchAllPrayers,
} from '../../store/Reducers/prayerReducer';

const loadMorePrayersButton = {
  marginTop: '20px',
  marginBottom: '20px',
  textAlign: 'center',
};

class MyPrayers extends Component {
  constructor(props) {
    super(props);
    this.state = { limit: 10 };
  }
  componentDidMount() {
    const currentGroupId = this.props.group.selectedGroup.groupId;
    if (currentGroupId) {
      this.props.fetchMyPrayersInGroup(currentGroupId, 5);
    }
  }

  handleChange = e => {};

  clickLoadMorePrayers = e => {
    e.preventDefault();
    this.props.fetchMyPrayersInGroup(
      this.props.group.selectedGroup.groupId,
      this.state.limit
    );
    this.state.limit += 5;
  };

  render() {
    const { myPrayers } = this.props.prayer;
    const selectedGroupId = this.props.group.selectedGroup.groupId;

    if (selectedGroupId) {
      return (
        <div className={'myGroups'}>
          <div className={'box'}>
            <h4 className="title is-3">My Prayers</h4>
            {myPrayers.map(prayer => {
              return (
                <div key={prayer.prayerId}>
                  <MyPrayerCard prayer={prayer} />
                </div>
              );
            })}

            {myPrayers.length > 0 && (
              <div style={loadMorePrayersButton}>
                <button className="button" onClick={this.clickLoadMorePrayers}>
                  Load More
                </button>
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className={'myGroups'}>
          <div className={'box'}>
            <h4 className="title is-3">My Prayers</h4>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    group: state.group,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    prayer: state.prayer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchGroups: () => dispatch(fetchGroups()),
    fetchMyPrayersInGroup: (groupId, limit) =>
      dispatch(fetchMyPrayersInGroup(groupId, limit)),
    fetchGroupPrayers: (groupId, limit) =>
      dispatch(fetchAllPrayers(groupId, limit)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPrayers);
