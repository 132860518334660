// import createHistory from 'history/createBrowserHistory';
// // import createMemoryHistory from 'history/createMemoryHistory';

// require('history').createBrowserHistory;
// require('history').createMemoryHistory;

// // const history =
// //   process.env.NODE_ENV === 'test' ? createMemoryHistory() : createHistory();

// const history = createHistory();

// const history = useRouterHistory(createBrowserHistory)({
//   basename: '/base-path',
// });

import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

export default history;
