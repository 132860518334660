import articleReducer from './articleReducer';
import authReducer from './authReducer';
import bannerReducer from './bannerReducer';
import commentReducer from './commentReducer';
import groupReducer from './groupReducer';
import prayerReducer from './prayerReducer';
import mailReducer from './mailReducer';
import memberReducer from './memberReducer';
import messageReducer from './messageReducer';
import notificationReducer from './notificationReducer';
import recentlyDiscussedReducer from './recentlyDiscussedReducer';
import scriptureReducer from './scriptureReducer';
import worshipReducer from './worshipReducer';

import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

const rootReducer = combineReducers({
  article: articleReducer,
  auth: authReducer,
  banner: bannerReducer,
  comment: commentReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  group: groupReducer,
  mail: mailReducer,
  members: memberReducer,
  message: messageReducer,
  notification: notificationReducer,
  prayer: prayerReducer,
  recentlyDiscussed: recentlyDiscussedReducer,
  scripture: scriptureReducer,
  worship: worshipReducer,
});

export default rootReducer;
