import React, { Component } from 'react';
import { connect } from 'react-redux';
import { deleteWorshipDiscussionComment } from '../../store/Reducers/commentReducer';

const commentSummary = {
  display: 'flex',
};
const commentInfo = {
  flex: '4',
};
const commentDelete = {
  flex: '1',
};

class WorshipDiscussionComment extends Component {
  deleteClick = (event, commentId, groupId, discussionId) => {
    event.preventDefault();
    this.props.deleteWorshipDiscussionComment(commentId, groupId, discussionId);
  };
  // if (props.comment.createdAt.seconds) {
  render() {
    return (
      <div className={'box'} style={commentSummary}>
        <div style={commentInfo}>
          <p>{this.props.comment.commentText}</p>
          <p>posted by {this.props.comment.displayAuthor}</p>
          {/* <p>{moment(props.comment.createdAt.toDate()).calendar()}</p> */}
          {/* </div> */}
          {/* <div style={commentDelete}> */}
          {this.props.auth.uid === this.props.comment.authorId && (
            <button
              onClick={event => {
                this.deleteClick(
                  event,
                  this.props.comment.commentId,
                  this.props.group.selectedGroup.groupId,
                  this.props.discussionId
                );
              }}
              className="button"
            >
              x
            </button>
          )}
        </div>
      </div>
    );
  }
  // } else {
  //   return (
  //     <div>
  //       <p>{props.comment.commentText}</p>
  //       <p>posted by {props.comment.authorName}</p>
  //     </div>
  //   );
  // }
}
const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    group: state.group,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteWorshipDiscussionComment: (commentId, groupId, discussionId) =>
      dispatch(
        deleteWorshipDiscussionComment(commentId, groupId, discussionId)
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorshipDiscussionComment);
