import React, { Component } from 'react';
import ArticleDiscussionComment from './ArticleDiscussionComment';
import { connect } from 'react-redux';
import moment from 'moment';
import CreateArticleDiscussionComment from './CreateArticleDiscussionComment';
import { fetchArticleDiscussionComments } from '../../store/Reducers/commentReducer';
import { fetchSingleDiscussion } from '../../store/Reducers/articleReducer';

const discussionDetail = {
  display: 'flex',
  flexWrap: 'wrap',
};
const discussionText = {
  flex: '1',
  marginRight: '40px',
  marginTop: '40px',
  marginBottom: '40px',
  marginLeft: '40px',
};
const discussionComments = {
  flexDirection: 'column',
  flex: '1',
  marginRight: '40px',
  marginLeft: '40px',
  marginBottom: '40px',
};

class ArticleDiscussionDetail extends Component {
  componentDidMount() {
    this.props.fetchArticleDiscussionComments(
      this.props.discussionId,
      this.props.groupId
    );
    this.props.fetchSingleDiscussion(
      this.props.discussionId,
      this.props.groupId
    );
  }
  handleClick = (event, articleLink) => {
    event.preventDefault();
    if (articleLink) {
      window.open(articleLink, '_blank');
    }
  };
  render() {
    const { discussion } = this.props;
    const comments = this.props.comments;

    const opts = {
      height: '390',
      width: '640',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
      },
    };
    if (discussion) {
      let articleLink = discussion.articleLink;
      let buttonText = 'No Article';
      if (discussion.articleLink) {
        buttonText = 'Open Article';
      }
      return (
        <div style={discussionDetail}>
          <div style={discussionText}>
            <div className={'box'}>
              <span className="title is-4">{discussion.title}</span>
              <br></br>
              <button
                onClick={event => {
                  this.handleClick(event, articleLink);
                }}
                className="button"
              >
                {buttonText}
              </button>

              <p>posted by {discussion.displayAuthor}</p>
              <br />
              <p>{discussion.discussionText}</p>
            </div>
          </div>

          <div style={discussionComments}>
            <div className={'box'}>
              <CreateArticleDiscussionComment />
            </div>
            {comments &&
              comments.map((comment, index) => {
                return (
                  <ArticleDiscussionComment
                    comment={comment}
                    key={index}
                    discussionId={this.props.discussionId}
                  />
                );
              })}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <p>Loading discussion...</p>
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const discussionId = ownProps.match.params.id;
  return {
    discussion: state.message.singleDiscussion,
    comments: state.comment.articleDiscussionComments,
    groupId: state.group.selectedGroup.groupId,
    discussionId: discussionId,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    fetchSingleDiscussion(discussionId, groupId) {
      dispatch(fetchSingleDiscussion(discussionId, groupId));
    },
    fetchArticleDiscussionComments(discussionId, groupId) {
      dispatch(fetchArticleDiscussionComments(discussionId, groupId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleDiscussionDetail);
