import React, { Component } from 'react';
import { connect } from 'react-redux';
import { deletePrayer } from '../../store/Reducers/prayerReducer';
import history from '../../history';

const prayerCardElements = {
  display: 'flex',

  marginBottom: '5px',
};

const PrayerInfo = {
  flex: '4',
};
const PrayerDelete = {
  flex: '1',
};

class MyPrayerCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClick = (event, prayer) => {
    event.preventDefault();
    history.push('/prayers/' + prayer.prayerId);
  };
  deleteClick = (event, prayerId, groupId) => {
    event.preventDefault();
    this.props.deletePrayer(prayerId, groupId);
  };

  render() {
    const { prayer } = this.props;
    return (
      <div>
        <div style={prayerCardElements}>
          <div
            style={PrayerInfo}
            onClick={event => {
              this.handleClick(event, prayer);
            }}
          >
            <h1 className="title is-4">{prayer.title}</h1>
          </div>
          <div style={PrayerDelete}>
            <button
              onClick={event => {
                this.deleteClick(
                  event,
                  prayer.prayerId,
                  this.props.group.selectedGroup.groupId
                );
              }}
              className="button"
            >
              x
            </button>
          </div>
        </div>
        <hr />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    group: state.group,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deletePrayer: (prayerId, groupId) =>
      dispatch(deletePrayer(prayerId, groupId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPrayerCard);
