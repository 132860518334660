import React from 'react';
import BannerDiscussionSummary from './BannerDiscussionSummary';
import { Link } from 'react-router-dom';

const BannerDiscussionList = ({ discussions }) => {
  return (
    <div>
      {discussions &&
        discussions.map(discussion => {
          return (
            <Link
              to={'/banner/' + discussion.discussionId}
              key={discussion.discussionId}
            >
              <BannerDiscussionSummary discussion={discussion} />
            </Link>
          );
        })}
    </div>
  );
};

export default BannerDiscussionList;
