import React, { Component } from 'react';
import { connect } from 'react-redux';
import PotentialMemberCard from './PotentialMemberCard';

class PotentialMemberList extends Component {
  state = {};

  componentDidMount() {}

  returnPotentialMembers = groupMembers => {
    const potentialMembers = [];
    groupMembers.forEach(member => {
      if (member.approvedMember === 'pending' && !member.isLeader) {
        potentialMembers.push(member);
      }
    });
    return potentialMembers;
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { groupMembers } = this.props.members;
    const groupId = this.props.group.selectedGroup.groupId;
    const groupName = this.props.group.selectedGroup.groupName;
    const potentialMembers = this.returnPotentialMembers(groupMembers);

    return (
      <div className={'myGroups'}>
        <div className={'box'}>
          <h4 className="title is-3">Member Requests</h4>
          {potentialMembers.map(member => {
            return (
              <div key={member.userId}>
                <PotentialMemberCard
                  member={member}
                  groupId={groupId}
                  groupName={groupName}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    group: state.group,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    members: state.members,
  };
};

export default connect(mapStateToProps, null)(PotentialMemberList);
