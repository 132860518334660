import React, { Component } from 'react';
import { connect } from 'react-redux';
import { signUp } from '../../store/Reducers/authReducer';

class MemberSignUp extends Component {
  state = {
    // groupName: '',
    email: '',
    password: '',
    name: '',
    // leaderPassword: '',
    // memberPassword: '',
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.signUp(this.state);
  };
  render() {
    const { authError } = this.props;
    return (
      <div className="box userSignup">
        <form onSubmit={this.handleSubmit}>
          <h3 className={'prayerReqTitle title is-3'}>Member Sign Up</h3>
          <p>
            This app is first and foremost a disscusion board for small group
            communication and spiritual growth.
          </p>

          <p>
            Prayer Requests and Discussions can only be viewed by Group Members.
          </p>
          <br></br>
          <p>This app is mobile friendly!</p>
          {/* <div className="input-field">
            <label htmlFor="groupName">Group Name</label>
            <input type="text" name="groupName" onChange={this.handleChange} />
          </div> */}

          <div className="input-field">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              onChange={this.handleChange}
              placeholder="email..."
            />
          </div>

          <div className="input-field">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              onChange={this.handleChange}
              placeholder="Name..."
            />
          </div>

          {/* <div className="input-field">
            <label htmlFor="password">Leader Password</label>
            <input
              type="password"
              name="leaderPassword"
              onChange={this.handleChange}
            />
          </div>
          <div className="input-field">
            <label htmlFor="password">Member Password</label>
            <input
              type="password"
              name="memberPassword"
              onChange={this.handleChange}
            />
          </div> */}
          <div className="input-field">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              onChange={this.handleChange}
              placeholder="password..."
            />
          </div>
          <div className="input-field">
            <button className="button">Sign Up</button>
          </div>
        </form>
        <div>{authError ? <p>{authError}</p> : null}</div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authError: state.auth.authError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signUp: newUser => dispatch(signUp(newUser)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberSignUp);
