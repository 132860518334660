const GET_NOTIFICATION_STATE = 'GET_NOTIFICATION_STATE';
const UPDATE_SINGLE_NOTIFICATION_STATE = 'UPDATE_SINGLE_NOTIFICATION_STATE';
const RESET = 'RESET';

const gotNotificationState = notificationSwitchStates => ({
  type: GET_NOTIFICATION_STATE,
  notificationSwitchStates,
});
const updateSingleSwitchState = singleSwitch => ({
  type: UPDATE_SINGLE_NOTIFICATION_STATE,
  singleSwitch,
});
const reset = () => ({ type: RESET });

export const resetSwitchState = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch(reset());
  };
};

export const fetchGroupNotificationStateForUser = groupId => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const userId = getState().firebase.auth.uid;

    firestore
      .collection('groups')
      .doc(groupId)
      .collection('members')
      .get()
      .then(members => {
        members.docs.forEach(groupMember => {
          let memberData = groupMember.data();
          if (memberData.userId === userId) {
            //update their switchStatus here
            firestore
              .collection('groups')
              .doc(groupId)
              .collection('members')
              .doc(groupMember.id)
              .get()
              .then(userProfile => {
                const userData = userProfile.data();
                const notificationSwitchStates = {
                  bannerNotificationSwitch: userData.bannerNotificationSwitch,
                  prayerNotificationSwitch: userData.prayerNotificationSwitch,
                  scriptureNotificationSwitch:
                    userData.scriptureNotificationSwitch,
                  worshipNotificationSwitch: userData.worshipNotificationSwitch,
                  messageNotificationSwitch: userData.messageNotificationSwitch,
                  articleNotificationSwitch: userData.articleNotificationSwitch,
                };
                dispatch(gotNotificationState(notificationSwitchStates));
              });
          }
        });
      });
  };
};

export const updateGroupNotificationSwitchForUser = (
  groupId,
  notificationSwitchName,
  switchState
) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const userId = getState().firebase.auth.uid;

    firestore
      .collection('groups')
      .doc(groupId)
      .collection('members')
      .get()
      .then(members => {
        members.docs.forEach(groupMember => {
          let memberData = groupMember.data();
          if (memberData.userId === userId) {
            //update their switchStatus here
            firestore
              .collection('groups')
              .doc(groupId)
              .collection('members')
              .doc(groupMember.id)
              .set({ [notificationSwitchName]: switchState }, { merge: true })
              .then(() => {
                dispatch(
                  updateSingleSwitchState({
                    notificationSwitchName,
                    switchState,
                  })
                );
              });
          }
        });
      });
  };
};

//INITIAL STATE-------------------------
const initState = {
  notificationSwitchStates: {
    bannerNotificationSwitch: false,
    prayerNotificationSwitch: false,
    scriptureNotificationSwitch: false,
    worshipNotificationSwitch: false,
    messageNotificationSwitch: false,
    articleNotificationSwitch: false,
  },
};

//REDUCER-------------------------------
const notificationReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_NOTIFICATION_STATE:
      return {
        ...state,
        notificationSwitchStates: action.notificationSwitchStates,
      };
    case UPDATE_SINGLE_NOTIFICATION_STATE:
      return {
        ...state,
        notificationSwitchStates: {
          ...state.notificationSwitchStates,
          [action.singleSwitch.notificationSwitchName]:
            action.singleSwitch.switchState,
        },
      };
    case RESET:
      return {
        ...state,
        notificationSwitchStates: {
          bannerNotificationSwitch: false,
          prayerNotificationSwitch: false,
          scriptureNotificationSwitch: false,
          worshipNotificationSwitch: false,
          messageNotificationSwitch: false,
          articleNotificationSwitch: false,
        },
      };
    default:
      return state;
  }
};
export default notificationReducer;
