import React, { Component } from 'react';
import { connect } from 'react-redux';

class LeaderLogin extends Component {
  state = {
    groupName: '',
    leaderPassword: '',
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = e => {
    e.preventDefault();
  };
  render() {
    return (
      <div className="container">
        <form onSubmit={this.handleSubmit}>
          <h5>Leader Login</h5>
          <div className="input-field">
            <label htmlFor="groupName">Group Name</label>
            <input type="text" name="groupName" onChange={this.handleChange} />
          </div>
          <div className="input-field">
            <label htmlFor="password">Leader Password</label>
            <input
              type="password"
              name="leaderPassword"
              onChange={this.handleChange}
            />
          </div>
          <div className="input-field">
            <button className="btn pink lighten-1 z-depth-0">Login</button>
          </div>
        </form>
      </div>
    );
  }
}

export default connect()(LeaderLogin);
