import React, { Component } from 'react';

class ProfileDashboard extends Component {
  render() {
    return (
      <div>
        <h1>Group Profile</h1>
        <p>Here is a place for group Info</p>
      </div>
    );
  }
}
export default ProfileDashboard;
