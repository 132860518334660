import React from 'react';
import PrayerSummary from './PrayerSummary';
import { Link } from 'react-router-dom';

const PrayerList = ({ prayers }) => {
  return (
    <div>
      {prayers &&
        prayers.map(prayer => {
          return (
            <Link to={'/prayers/' + prayer.prayerId} key={prayer.prayerId}>
              <PrayerSummary prayer={prayer} />
            </Link>
          );
        })}
    </div>
  );
};

export default PrayerList;
