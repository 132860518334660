import React, { Component } from 'react';
import { connect } from 'react-redux';

import UpdateBannerForm from './UpdateBannerForm';
import AddBannerDiscussionForm from './AddBannerDiscussionForm';
import BannerDiscussionList from './BannerDiscussionList';

import {
  fetchAllDiscussions,
  loadBannerInfo,
} from '../../store/Reducers/bannerReducer';

const bannerBox = {
  marginRight: '20px',
  marginTop: '20px',
  marginBottom: '40px',
  marginLeft: '20px',
};

const bannerDashboardComp = {
  display: 'flex',
  flexWrap: 'wrap',
};

const bannerInfo = {
  flex: '1',
  marginBottom: '40px',
  flexBasis: '250px',
};
const prayerListSection = {
  flexDirection: 'column',
  flex: '1',
  marginRight: '20px',
  marginLeft: '20px',
  flexBasis: '200px',
  justifyContent: 'left',
};

const prayerList = {};

const loadMoreBannerDiscussionsButton = {
  marginTop: '20px',
  marginBottom: '20px',
  textAlign: 'center',
};

class BannerDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = { limit: 5 };
  }

  componentDidMount() {
    this.props.fetchGroupBannerDiscussions(
      this.props.group.selectedGroup.groupId,
      this.state.limit
    );
    this.state.limit += 5;
    this.props.loadBannerInfo(this.props.selectedGroupId);
  }
  clickLoadMoreBannerDiscussions = e => {
    e.preventDefault();
    this.props.fetchGroupBannerDiscussions(
      this.props.group.selectedGroup.groupId,
      this.state.limit
    );
    this.state.limit += 5;
  };

  render() {
    //I believe these can be deleted
    const { leaders } = this.props.group.selectedGroup;
    const currentUser = this.props.auth.uid;
    //----------------------

    //this logic will determine if the current user is the leader of the seleected group
    let selectedGroupId = this.props.group.selectedGroup.groupId;
    let isLeader = false;
    const myGroups = this.props.group.myGroups;
    myGroups.forEach(group => {
      if (group.groupId === selectedGroupId)
        if (group.isLeader === true) isLeader = true;
    });
    //-----------------------------------

    const { discussions } = this.props.banner;
    const { bannerInfo } = this.props.banner;
    return (
      <div className={'box'} style={bannerBox}>
        <h1 className={'title is-3'}>{this.props.groupName}'s Banner</h1>
        <div style={bannerDashboardComp}>
          <div style={bannerInfo}>
            <h1 className={'title is-3'}>{bannerInfo.eventTitle}</h1>
            <h1 className={'prayerReqTitle title is-4'}>
              {bannerInfo.eventDate}
            </h1>
            <h1 className={'title is-4'}>{bannerInfo.eventAddress}</h1>
            <h1 className={'title is-5'}>{bannerInfo.eventInfo}</h1>
            <AddBannerDiscussionForm />
            {isLeader && <UpdateBannerForm />}
          </div>
          <div style={prayerListSection}>
            <BannerDiscussionList discussions={discussions} />
            <div style={loadMoreBannerDiscussionsButton}>
              <button
                className="button"
                onClick={this.clickLoadMoreBannerDiscussions}
              >
                Load More
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    groupName: state.group.selectedGroup.groupName,
    selectedGroupId: state.group.selectedGroup.groupId,
    group: state.group,
    banner: state.banner,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchGroupBannerDiscussions: (groupId, limit) =>
      dispatch(fetchAllDiscussions(groupId, limit)),

    loadBannerInfo: groupId => dispatch(loadBannerInfo(groupId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BannerDashboard);
