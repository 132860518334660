//ACTION TYPES--------------------------
const LOAD_RECENT = 'LOAD_RECENT';

//ACTION CREATORS-----------------------

const loadMostRecent = mostRecentArray => ({
  type: LOAD_RECENT,
  mostRecentArray,
});

//THUNK CREATORS------------------------

export const fetchRecentlyDiscussedInGroup = groupId => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();

  firestore
    .collection('groups')
    .doc(groupId)
    .collection('recentlyDiscussed')
    .doc('mostRecent')
    .get()
    .then(resp => {
      const mostRecentArray = resp.data().discussions;
      dispatch(loadMostRecent(mostRecentArray));
    });
};

//INITIAL STATE-------------------------

const initState = {
  recentlyDiscussed: [],
};

//REDUCER-------------------------------

const recentlyDiscussedReducer = (state = initState, action) => {
  switch (action.type) {
    case LOAD_RECENT:
      return {
        ...state,
        recentlyDiscussed: action.mostRecentArray,
      };
    default:
      return state;
  }
};
export default recentlyDiscussedReducer;
