import history from '../../history';
//ACTION TYPES--------------------------
const LOAD_ALL_PRAYERS = 'LOAD_ALL_PRAYERS';
const LOAD_SINGLE_PRAYER = 'LOAD_SINGLE_PRAYER';
const LOAD_MY_PRAYERS = 'LOAD_MY_PRAYERS';
const ADD_PRAYER = 'ADD_PRAYER';
const ADD_PRAYER_ERROR = 'ADD_PRAYER_ERROR';

//const CREATE_PRAYER = 'CREATE_PRAYER';
const REMOVE_PRAYER = 'REMOVE_PRAYER';

//ACTION CREATORS-----------------------

const loadAllPrayers = prayerArray => ({ type: LOAD_ALL_PRAYERS, prayerArray });
const loadMyPrayers = prayerArray => ({ type: LOAD_MY_PRAYERS, prayerArray });
const loadPrayer = prayer => ({ type: LOAD_SINGLE_PRAYER, prayer });
const addPrayer = prayer => ({ type: ADD_PRAYER, prayer });
const removePrayer = prayerId => ({ type: REMOVE_PRAYER, prayerId });
//const createPrayer = prayer => ({ type: CREATE_PRAYER, prayer });

//THUNK CREATORS------------------------

export const fetchAllPrayers = (groupId, limit) => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();

  const prayerRef = firestore
    .collection('groups')
    .doc(groupId)
    .collection('prayers');

  const query = prayerRef.orderBy('createdAt', 'desc').limit(limit);

  query.get().then(prayers => {
    let returnArray = [];
    prayers.docs.forEach(prayer => {
      returnArray.push({ ...prayer.data(), prayerId: prayer.id });
    });
    dispatch(
      loadAllPrayers(
        returnArray.sort((a, b) => {
          return b.createdAt.seconds - a.createdAt.seconds;
        })
      )
    );
  });
};
export const fetchMyPrayersInGroup = (groupId, limit) => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();
  const userId = getState().firebase.auth.uid;

  const prayerRef = firestore
    .collection('groups')
    .doc(groupId)
    .collection('prayers');

  const query = prayerRef
    .orderBy('createdAt', 'desc')
    .where('authorId', '==', userId)
    .limit(limit);

  query.get().then(prayers => {
    let returnArray = [];
    prayers.docs.forEach(prayer => {
      returnArray.push({ ...prayer.data(), prayerId: prayer.id });
    });
    dispatch(
      loadMyPrayers(
        returnArray.sort((a, b) => {
          return b.createdAt.seconds - a.createdAt.seconds;
        })
      )
    );
  });
};

export const fetchSinglePrayer = (prayerId, groupId) => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();
  firestore
    .collection('groups')
    .doc(groupId)
    .collection('prayers')
    .doc(prayerId)
    .get()
    .then(prayer => {
      dispatch(loadPrayer({ ...prayer.data(), prayerId: prayer.id }));
    });
};

export const createPrayer = (prayer, group) => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();
  const profile = getState().firebase.profile;
  const authorId = getState().firebase.auth.uid;

  if (prayer.displayAuthor === '') {
    prayer.displayAuthor = 'Group Member';
  }

  firestore
    .collection('groups')
    .doc(group.groupId)
    .collection('prayers')
    .add({
      ...prayer,
      author: profile.name,
      authorId: authorId,
      createdAt: new Date(),
      groupId: group.groupId,
    })
    .then(resp => {
      dispatch(
        addPrayer({
          ...prayer,
          prayerId: resp.id,
          author: profile.name,
          authorId: authorId,
          createdAt: new Date(),
          groupId: group.groupId,
        })
      );
      history.push('/prayers');
    })

    .catch(err => {
      dispatch({ type: ADD_PRAYER_ERROR, err });
    });
};

export const deletePrayer = (prayerId, groupId) => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();

  firestore
    .collection('groups')
    .doc(groupId)
    .collection('prayers')
    .doc(prayerId)
    .collection('comments')
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        firestore
          .collection('groups')
          .doc(groupId)
          .collection('prayers')
          .doc(prayerId)
          .collection('comments')
          .doc(doc.id)
          .delete();
      });
    })
    .then(() => {
      firestore
        .collection('groups')
        .doc(groupId)
        .collection('prayers')
        .doc(prayerId)
        .delete()
        .then(() => {
          dispatch(removePrayer(prayerId));
        });
    });
};

//INITIAL STATE-------------------------

const initState = {
  singlePrayer: {},
  groupPrayers: [],
  myPrayers: [],
};

//REDUCER-------------------------------

const prayerReducer = (state = initState, action) => {
  switch (action.type) {
    case LOAD_ALL_PRAYERS:
      return {
        ...state,
        groupPrayers: action.prayerArray,
      };
    case LOAD_SINGLE_PRAYER:
      return {
        ...state,
        singlePrayer: action.prayer,
      };
    case LOAD_MY_PRAYERS:
      return {
        ...state,
        myPrayers: action.prayerArray,
      };
    case ADD_PRAYER:
      return {
        ...state,
        groupPrayers: [action.prayer, ...state.groupPrayers],
      };
    case REMOVE_PRAYER:
      return {
        ...state,
        groupPrayers: state.groupPrayers.filter(prayer => {
          return prayer.prayerId !== action.prayerId;
        }),
        myPrayers: state.myPrayers.filter(prayer => {
          return prayer.prayerId !== action.prayerId;
        }),
      };
    case ADD_PRAYER_ERROR:
      return state;
    default:
      return state;
  }
};
export default prayerReducer;
