import { getFirestore } from 'redux-firestore';
import history from '../../history';
import { getFirebase } from 'react-redux-firebase';

//ACTION TYPES--------------------------
const CREATE_GROUP = 'CREATE_GROUP';
const CREATE_GROUP_ERROR = 'CREATE_GROUP_ERROR';
const GET_GROUP = 'GET_GROUP';
const GET_GROUPS_ERROR = 'GET_GROUPS';
const LEAVE_GROUP = 'LEAVE_GROUP';
const SELECT_GROUP = 'SELECT_GROUP';
const CLEAR_STATE = 'CLEAR_STATE';
const CHANGE_REFRESH_NEEDED = 'CHANGE_REFRESH_NEEDED';

//ACTION CREATORS-----------------------
const createGroupSuccess = group => ({ type: CREATE_GROUP, group });
const createGroupError = err => ({ type: CREATE_GROUP_ERROR, err });
const gotGroup = group => ({ type: GET_GROUP, group });
const getGroupsError = err => ({ type: GET_GROUPS_ERROR, err });
const leftGroup = group => ({ type: LEAVE_GROUP, group });
const changeRefreshNeeded = boolValue => ({
  type: CHANGE_REFRESH_NEEDED,
  boolValue,
});
export const selectGroup = (groupName, id, isLeader) => ({
  type: SELECT_GROUP,
  groupName,
  id,
  isLeader,
});
export const clearGroupState = () => ({ type: CLEAR_STATE });
//THUNK CREATORS------------------------

export const createGroup = newGroup => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    const profile = getState().firebase.profile;
    const userId = getState().firebase.auth.uid;

    firestore
      .collection('groups')
      .where('groupName', '==', newGroup.groupName)
      .get()
      .then(snapshot => {
        if (snapshot.docs.length === 0) {
          //create group document---------------
          firestore
            .collection('groups')
            .add({
              ...newGroup,
            })
            .then(group => {
              //add group ID to user?
              firestore
                .collection('users')
                .doc(userId)
                .set(
                  {
                    groupsKey: firebase.firestore.FieldValue.arrayUnion(
                      group.id
                    ),
                    myGroups: {
                      [newGroup.groupName]: {
                        groupId: group.id,
                        groupName: newGroup.groupName,
                        isLeader: true,
                        approvedMember: true,
                      },
                    },
                  },
                  { merge: true }
                )
                .then(() => {
                  //create banner document------------
                  firestore
                    .collection('groups')
                    .doc(group.id)
                    .collection('banner')
                    .doc('bannerInfo')
                    .set({
                      eventTitle: '',
                      eventDate: '',
                      eventAddress: '',
                      eventInfo: '',
                    });
                  firestore
                    .collection('groups')
                    .doc(group.id)
                    .collection('recentlyDiscussed')
                    .doc('mostRecent')
                    .set({ discussions: [] });
                  //create member collection
                  firestore
                    .collection('groups')
                    .doc(group.id)
                    .collection('members')
                    .add({
                      userId: userId,
                      email: profile.email,
                      name: profile.name,
                      isLeader: true,
                      approvedMember: true,
                    })
                    .catch(err => {
                      console.log(err);
                    });
                  //add group that was created to local state
                  dispatch(
                    createGroupSuccess({
                      groupName: newGroup.groupName,
                      groupId: group.id,
                      isLeader: true,
                      approvedMember: true,
                    })
                  );
                });
            })
            .catch(err => {
              dispatch(createGroupError(err));
            });
        }
      });
  };
};
export const joinGroup = group => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const groupName = group.groupName;
    const groupPassword = group.password;

    const groupRef = firestore.collection('groups');
    const query = groupRef
      .where('groupName', '==', groupName)
      .where('password', '==', groupPassword);

    const profile = getState().firebase.profile;
    const userId = getState().firebase.auth.uid;

    query
      .get()
      .then(resp => {
        const groupId = resp.docs[0].id;

        firestore
          .collection('users')
          .doc(userId)
          .update({
            groupsKey: firebase.firestore.FieldValue.arrayUnion(groupId),
          })
          .then(() => {
            firestore
              .collection('groups')
              .doc(groupId)
              .collection('members')
              .where('userId', '==', userId)
              .get()
              .then(user => {
                let userDoesNotExist = user.docs.length === 0;
                if (userDoesNotExist) {
                  //add to member collection------
                  firestore
                    .collection('groups')
                    .doc(groupId)
                    .collection('members')
                    .add({
                      userId: userId,
                      email: profile.email,
                      name: profile.name,
                      isLeader: false,
                      approvedMember: 'pending',
                    });
                } else console.log('nope');
              });

            //==========
            //add group ID to user's groups array---------------------
            firestore
              .collection('users')
              .doc(userId)
              .set(
                {
                  myGroups: {
                    [group.groupName]: {
                      groupId: groupId,
                      groupName: group.groupName,
                      isLeader: false,
                      approvedMember: 'pending',
                    },
                  },
                },
                { merge: true }
              );

            // ---------------------------------------------------------
            //========
            dispatch(
              createGroupSuccess({
                groupName: group.groupName,
                isLeader: false,
                approvedMember: 'pending',
                groupId: groupId,
              })
            );
          });
      })
      .catch(err => {
        console.log('groupname or password was probably incorrect');

        console.log(err);
      });
  };
};

export const fetchGroups = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const userId = getState().firebase.auth.uid;

    firestore
      .collection('users')
      .doc(userId)
      .get()
      .then(user => {
        //new way------------------------
        //anytime I fetch I need to see if the leader approved me on their end
        //I as a user will be responsible for updating my end
        const myGroups = user.data().myGroups;
        let groupsKey = user.data().groupsKey;
        for (let groupName in myGroups) {
          if (myGroups.hasOwnProperty(groupName)) {
            let isUserInGroup = false;
            let groupId = myGroups[groupName].groupId;
            //check the status of the member(if they dont exist the user was removed, sooo remove the id from the key and update the user accordingly. see memberReducer line 108)
            firestore
              .collection('groups')
              .doc(groupId)
              .collection('members')
              .get()
              // eslint-disable-next-line no-loop-func
              .then(members => {
                members.docs.forEach(groupMember => {
                  let memberData = groupMember.data();
                  if (memberData.userId === userId) {
                    // update user groups info to that status
                    firestore
                      .collection('users')
                      .doc(userId)
                      .set(
                        {
                          myGroups: {
                            [groupName]: {
                              groupId: groupId,
                              groupName: groupName,
                              isLeader: memberData.isLeader,
                              approvedMember: memberData.approvedMember,
                            },
                          },
                        },
                        { merge: true }
                      )
                      .then(() => {
                        dispatch(
                          gotGroup({
                            // ...myGroups[groupName],
                            groupId: groupId,
                            groupName: groupName,
                            isLeader: memberData.isLeader,
                            approvedMember: memberData.approvedMember,
                          })
                        );
                      });
                    isUserInGroup = true;
                  }
                });
                //delete them they aren't in the group
                if (!isUserInGroup) {
                  delete myGroups[groupName];

                  groupsKey = groupsKey.filter(id => {
                    return id !== groupId;
                  });

                  firestore
                    .collection('users')
                    .doc(userId)
                    .update({
                      groupsKey: groupsKey,
                      myGroups: {
                        ...myGroups,
                      },
                    });
                }
              });
          }
        }
      });
    dispatch(changeRefreshNeeded(false));
    // history.push('/home');
  };
};
export const checkGroupMembership = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const userId = getState().firebase.auth.uid;
    let leaderChange = false;
    let isUserInGroup = false;
    let membershipStatusChange = false;

    firestore
      .collection('users')
      .doc(userId)
      .get()
      .then(user => {
        //new way------------------------
        //anytime I fetch I need to see if the leader approved me on their end
        //I as a user will be responsible for updating my end
        const myGroups = user.data().myGroups;
        for (let groupName in myGroups) {
          if (myGroups.hasOwnProperty(groupName)) {
            let groupId = myGroups[groupName].groupId;

            firestore
              .collection('groups')
              .doc(groupId)
              .collection('members')
              .get()
              // eslint-disable-next-line no-loop-func
              .then(members => {
                members.docs.forEach(groupMember => {
                  let memberData = groupMember.data();

                  if (memberData.userId === userId) {
                    //user is in group
                    isUserInGroup = true;
                    //check for status change from pending to approved
                    if (
                      memberData.approvedMember !==
                      myGroups[groupName].approvedMember
                    ) {
                      membershipStatusChange = true;
                    }
                    //check for leadership status change
                    if (memberData.isLeader !== myGroups[groupName].isLeader) {
                      leaderChange = true;
                    }
                    if (
                      leaderChange === true ||
                      membershipStatusChange === true
                    ) {
                      dispatch(changeRefreshNeeded(true));
                    }
                  }
                });
                if (isUserInGroup === false) {
                  dispatch(changeRefreshNeeded(true));
                }

                leaderChange = false;
                isUserInGroup = false;
                membershipStatusChange = false;
              });
          }
        }
      });
  };
};

export const leaveGroup = group => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const userId = getState().firebase.auth.uid;
    const groupId = group.groupId;
    let memberQuery = firestore
      .collection('groups')
      .doc(groupId)
      .collection('members')
      .where('userId', '==', userId);
    memberQuery
      .get()
      .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          doc.ref.delete();
        });
      })
      .then(() => {
        firestore
          .collection('groups')
          .doc(groupId)
          .collection('members')
          .get()
          .then(members => {
            //if group has no members //member collection.length?
            //then delete the group
            if (members.empty) {
              firestore
                .collection('groups')
                .doc(groupId)
                .delete();
            }
          });
      })
      .then(() => {
        firestore
          .collection('users')
          .doc(userId)
          .get()
          .then(user => {
            const myGroups = user.data().myGroups;
            let groupsKey = user.data().groupsKey;
            delete myGroups[group.groupName];

            groupsKey = groupsKey.filter(id => {
              return id !== groupId;
            });

            firestore
              .collection('users')
              .doc(userId)
              .update({
                groupsKey: groupsKey,
                myGroups: {
                  ...myGroups,
                },
              });
          });
      })
      .then(() => {
        //-----------------------------
        //refresh the state
        dispatch(leftGroup(group));
        history.push('/home');
      });
  };
};

//INITIAL STATE-------------------------
const initState = {
  refreshNeeded: false,
  myGroups: [],
  selectedGroup: { groupName: '', groupId: '', leaders: '' },
};

//REDUCER-------------------------------
// eslint-disable-next-line complexity
const groupReducer = (state = initState, action) => {
  switch (action.type) {
    case CREATE_GROUP:
      return {
        ...state,
        myGroups: [...state.myGroups, action.group],
      };
    case CREATE_GROUP_ERROR:
      return state;
    case GET_GROUP:
      return {
        ...state,
        myGroups: [...state.myGroups, action.group],
        selectedGroup: { groupName: '', groupId: '' },
      };
    case LEAVE_GROUP:
      return {
        ...state,
        myGroups: state.myGroups.filter(
          group => group.groupId !== action.group.groupId
        ),
        selectedGroup: { groupName: '', groupId: '' },
      };
    case SELECT_GROUP:
      return {
        ...state,
        selectedGroup: {
          groupName: action.groupName,
          groupId: action.id,
          isLeader: action.isLeader,
        },
      };
    case GET_GROUPS_ERROR:
      return state;
    case CLEAR_STATE:
      return initState;
    case CHANGE_REFRESH_NEEDED:
      return {
        ...state,
        refreshNeeded: action.boolValue,
      };
    default:
      return state;
  }
};
export default groupReducer;
