import React, { Component } from 'react';
import MemberList from './MemberList';
import PotentialMemberList from './PotentialMemberList';
class LeaderDashboard extends Component {
  render() {
    return (
      <div>
        <MemberList />
        <PotentialMemberList />
      </div>
    );
  }
}
export default LeaderDashboard;
