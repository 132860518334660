import React from 'react';
import { connect } from 'react-redux';
import { removeLeader, removeMember } from '../../store/Reducers/memberReducer';

const LeaderCard = props => {
  const handleRemoveMemberClick = e => {
    e.preventDefault();
    props.removeMember(props.groupId, props.member.userId, props.groupName);
  };
  const handleRemoveLeaderClick = e => {
    e.preventDefault();
    props.removeLeader(props.groupId, props.member.userId);
    // location.reload();
  };
  const { member } = props;
  let name = props.member.name;
  if (member.isLeader) name += ' - Leader';
  if (props)
    return (
      <div>
        <h1 className="title is-4">{name}</h1>
        <button className="button is-small" onClick={handleRemoveMemberClick}>
          Remove
        </button>
        <button className="button is-small" onClick={handleRemoveLeaderClick}>
          Remove Leader
        </button>
      </div>
    );
};

const mapDispatchToProps = dispatch => {
  return {
    removeLeader: (groupId, userId) => dispatch(removeLeader(groupId, userId)),
    removeMember: (groupId, userId, groupName) =>
      dispatch(removeMember(groupId, userId, groupName)),
  };
};

export default connect(null, mapDispatchToProps)(LeaderCard);
