import React from 'react';
import { connect } from 'react-redux';
import SignedInLinks from './SignedInLinks';
import SignedOutLinks from './SignedOutLinks';

const Navbar = props => {
  const { auth, profile, group } = props;
  const links = auth.uid ? <SignedInLinks /> : <SignedOutLinks />;

  return (
    <div>
      <div className={'navTitle'}>
        <h1 className="title is-3">
          Small Group Chat for {group.selectedGroup.groupName}
        </h1>
      </div>
      <nav>
        <div className={'navLinks'}>{links}</div>
      </nav>
      <hr />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    group: state.group,
  };
};

export default connect(mapStateToProps)(Navbar);
