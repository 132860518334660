import React, { Component } from 'react';
import YouTube from 'react-youtube';

const infoSection = {
  flexDirection: 'column',
  // flex: '1',
  // marginRight: '20px',
  // marginLeft: '20px',
  // flexBasis: '200px',
  textAlign: 'center',
  justifyContent: 'center',
};

export default class Info extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const opts = {
      height: '390',
      width: '640',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
      },
    };
    return (
      <div style={infoSection}>
        <h1 className={'title is-4'}>Welcome to Small Group Chat</h1>
        <p>
          This is a project I made to organize spiritual small groups I am a
          part of.
        </p>
        <p>
          I want this app to be a place where groups can have a safe place to
          dicuss and pray,
        </p>
        <p>
          where people can have an evolving dialog without the pressures of
          modern social media.
        </p>
        <br />
        <p>I aim to improve the quality and functionality of this app.</p>
        <p>
          If you find a part that isn't working right, or you have an idea to
          improve,
        </p>
        <p>please feel free to email</p>
        <strong>smallgroupchatapp@gmail.com</strong>
        {/* <p>
            Right now the only way you can log in to use it is through Google. I
            will try to update it frequently.
          </p>
          <p>
            If you can help support server costs and the time it takes to make
            new features and apps I would be extremly grateful!
          </p>
          <p>Here is my Patreon...</p>
          <div className="patreonLink">
            <a href="https://www.patreon.com/user?u=16538646">
              patreon.com/me!
            </a>
          </div> */}
        <br />
        <br />
        <div className="infoVideo">
          <YouTube
            videoId={'L84YI9xSMYc'}
            opts={opts}
            onReady={this.videoReady}
          />
        </div>
      </div>
    );
  }
}
