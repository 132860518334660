import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddArticleDiscussionForm from './AddArticleDiscussionForm';
import ArticleDiscussionList from './ArticleDiscussionList';
import { fetchAllDiscussions } from '../../store/Reducers/articleReducer';

const articleBox = {
  marginRight: '20px',
  marginTop: '20px',
  marginBottom: '40px',
  marginLeft: '20px',
};

const articleDashboardComp = {
  display: 'flex',
  flexWrap: 'wrap',
};

const articleInfo = {
  flex: '1',
  marginBottom: '40px',
  flexBasis: '250px',
};
const articleListSection = {
  flexDirection: 'column',
  flex: '1',
  marginRight: '20px',
  marginLeft: '20px',
  flexBasis: '200px',
  justifyContent: 'left',
};

const loadMoreArticleDiscussionsButton = {
  marginTop: '20px',
  marginBottom: '20px',
  textAlign: 'center',
};

class ArticleDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = { limit: 5 };
  }

  componentDidMount() {
    this.props.fetchGroupArticleDiscussions(
      this.props.group.selectedGroup.groupId,
      this.state.limit
    );
    this.state.limit += 5;
  }
  clickLoadMoreArticleDiscussions = e => {
    e.preventDefault();
    this.props.fetchGroupArticleDiscussions(
      this.props.group.selectedGroup.groupId,
      this.state.limit
    );
    this.state.limit += 5;
  };

  render() {
    const { discussions } = this.props.article;
    return (
      <div className={'box'} style={articleBox}>
        <h1 className={'title is-3'}>Article Discussions</h1>
        <div style={articleDashboardComp}>
          <div style={articleInfo}>
            <AddArticleDiscussionForm />
          </div>
          <div style={articleListSection}>
            <ArticleDiscussionList discussions={discussions} />
            <div style={loadMoreArticleDiscussionsButton}>
              <button
                className="button"
                onClick={this.clickLoadMoreArticleDiscussions}
              >
                Load More
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    groupName: state.group.selectedGroup.groupName,
    group: state.group,
    article: state.article,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchGroupArticleDiscussions: (groupId, limit) =>
      dispatch(fetchAllDiscussions(groupId, limit)),
  };
};

// export default compose(
//   firestoreConnect([{ collection: 'prayers', orderBy: ['createdAt', 'desc'] }]),
//   connect(mapStateToProps)
// )(PrayerDashboard);
export default connect(mapStateToProps, mapDispatchToProps)(ArticleDashboard);
