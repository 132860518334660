import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  updateGroupNotificationSwitchForUser,
  fetchGroupNotificationStateForUser,
  resetSwitchState,
} from '../../store/Reducers/notificationReducer';

const SectionNotificationsSettingsRow = {
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '220px',
  height: '60px',
};

const submitFormButton = {
  flex: '1',
  textAlign: 'center',
  alignSelf: 'flex-end',
};
const yesButton = {
  backgroundColor: '#f44336',
};

class NotificationsSettingsBox extends Component {
  // state = {
  //   bannerSwitch: false,
  //   prayerSwitch: false,
  //   scriptureSwitch: false,
  //   worshipSwitch: false,
  // };

  handleSubmit = e => {
    e.preventDefault();
    this.props.updateGroupNotificationSwitchForUser();
  };
  componentDidMount() {
    if (this.props.group.selectedGroup.groupId) {
      this.props.fetchGroupNotificationStateForUser(
        this.props.group.selectedGroup.groupId
      );
    }
  }
  // componentDidUpdate() {
  //   this.props.resetSwitchState();
  // }
  //WHAT I NEED TO DO...
  //USE this.props instead of this.state for the className logic of the buttons
  //SEE MyPrayers.js as an example. it grabs the prayers from this.props

  // eslint-disable-next-line complexity
  render() {
    const selectedGroupId = this.props.group.selectedGroup.groupId;
    const { notificationSwitchStates } = this.props.notification;
    if (selectedGroupId) {
      return (
        <form onSubmit={this.handleSubmit}>
          <div className={'box'}>
            <h4 className="title is-4">
              Notification Settings for Discussion Posts
            </h4>
            <p>
              Recieve an email for every post created by a group member in
              discussion sections you set to yes
            </p>
            {/* ------------------------------------------------------------- */}
            {/* Banner Section ----------------------------- */}
            <div style={SectionNotificationsSettingsRow}>
              <label>Banner</label>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    // this.setState({ bannerSwitch: false });
                    this.props.updateGroupNotificationSwitchForUser(
                      selectedGroupId,
                      'bannerNotificationSwitch',
                      false
                    );
                  }}
                  className={`${
                    notificationSwitchStates.bannerNotificationSwitch
                      ? 'button'
                      : 'button  is-black'
                  }`}
                >
                  No
                </button>
                <button
                  type="button"
                  onClick={() => {
                    // this.setState({ bannerSwitch: true });
                    this.props.updateGroupNotificationSwitchForUser(
                      selectedGroupId,
                      'bannerNotificationSwitch',
                      true
                    );
                  }}
                  className={`${
                    notificationSwitchStates.bannerNotificationSwitch
                      ? 'button is-success'
                      : 'button'
                  }`}
                >
                  Yes
                </button>
              </div>
            </div>
            {/* ------------------------------------------------------------- */}
            {/* ------------------------------------------------------------- */}
            {/* ------------------------------------------------------------- */}
            {/* Prayer Section ----------------------------- */}
            <div style={SectionNotificationsSettingsRow}>
              <label>Prayer</label>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    // this.setState({ prayerSwitch: false });
                    this.props.updateGroupNotificationSwitchForUser(
                      selectedGroupId,
                      'prayerNotificationSwitch',
                      false
                    );
                  }}
                  className={`${
                    notificationSwitchStates.prayerNotificationSwitch
                      ? 'button'
                      : 'button  is-black'
                  }`}
                >
                  No
                </button>
                <button
                  type="button"
                  onClick={() => {
                    // this.setState({ prayerSwitch: true });
                    this.props.updateGroupNotificationSwitchForUser(
                      selectedGroupId,
                      'prayerNotificationSwitch',
                      true
                    );
                  }}
                  className={`${
                    notificationSwitchStates.prayerNotificationSwitch
                      ? 'button is-success'
                      : 'button'
                  }`}
                >
                  Yes
                </button>
              </div>
            </div>
            {/* ------------------------------------------------------------- */}
            {/* ------------------------------------------------------------- */}
            {/* ------------------------------------------------------------- */}
            {/* Scripture Section ----------------------------- */}
            <div style={SectionNotificationsSettingsRow}>
              <label>Scripture</label>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    // this.setState({ scriptureSwitch: false });
                    this.props.updateGroupNotificationSwitchForUser(
                      selectedGroupId,
                      'scriptureNotificationSwitch',
                      false
                    );
                  }}
                  className={`${
                    notificationSwitchStates.scriptureNotificationSwitch
                      ? 'button'
                      : 'button  is-black'
                  }`}
                >
                  No
                </button>
                <button
                  type="button"
                  onClick={() => {
                    // this.setState({ scriptureSwitch: true });
                    this.props.updateGroupNotificationSwitchForUser(
                      selectedGroupId,
                      'scriptureNotificationSwitch',
                      true
                    );
                  }}
                  className={`${
                    notificationSwitchStates.scriptureNotificationSwitch
                      ? 'button is-success'
                      : 'button'
                  }`}
                >
                  Yes
                </button>
              </div>
            </div>
            {/* ------------------------------------------------------------- */}
            {/* ------------------------------------------------------------- */}
            {/* ------------------------------------------------------------- */}
            {/* Worship Section ----------------------------- */}
            <div style={SectionNotificationsSettingsRow}>
              <label>Worship</label>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    // this.setState({ worshipSwitch: false });
                    this.props.updateGroupNotificationSwitchForUser(
                      selectedGroupId,
                      'worshipNotificationSwitch',
                      false
                    );
                  }}
                  className={`${
                    notificationSwitchStates.worshipNotificationSwitch
                      ? 'button'
                      : 'button  is-black'
                  }`}
                >
                  No
                </button>
                <button
                  type="button"
                  onClick={() => {
                    // this.setState({ worshipSwitch: true });
                    this.props.updateGroupNotificationSwitchForUser(
                      selectedGroupId,
                      'worshipNotificationSwitch',
                      true
                    );
                  }}
                  className={`${
                    notificationSwitchStates.worshipNotificationSwitch
                      ? 'button is-success'
                      : 'button'
                  }`}
                >
                  Yes
                </button>
              </div>
            </div>
            {/* ------------------------------------------------------------- */}
            {/* ------------------------------------------------------------- */}
            {/* ------------------------------------------------------------- */}
            {/* Message Section ----------------------------- */}
            <div style={SectionNotificationsSettingsRow}>
              <label>Message</label>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    // this.setState({ messageSwitch: false });
                    this.props.updateGroupNotificationSwitchForUser(
                      selectedGroupId,
                      'messageNotificationSwitch',
                      false
                    );
                  }}
                  className={`${
                    notificationSwitchStates.messageNotificationSwitch
                      ? 'button'
                      : 'button  is-black'
                  }`}
                >
                  No
                </button>
                <button
                  type="button"
                  onClick={() => {
                    // this.setState({ messageSwitch: true });
                    this.props.updateGroupNotificationSwitchForUser(
                      selectedGroupId,
                      'messageNotificationSwitch',
                      true
                    );
                  }}
                  className={`${
                    notificationSwitchStates.messageNotificationSwitch
                      ? 'button is-success'
                      : 'button'
                  }`}
                >
                  Yes
                </button>
              </div>
            </div>
            {/* ------------------------------------------------------------- */}
            {/* ------------------------------------------------------------- */}
            {/* ------------------------------------------------------------- */}
            {/* Articles Section ----------------------------- */}
            <div style={SectionNotificationsSettingsRow}>
              <label>Articles</label>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    // this.setState({ articlesSwitch: false });
                    this.props.updateGroupNotificationSwitchForUser(
                      selectedGroupId,
                      'articleNotificationSwitch',
                      false
                    );
                  }}
                  className={`${
                    notificationSwitchStates.articleNotificationSwitch
                      ? 'button'
                      : 'button  is-black'
                  }`}
                >
                  No
                </button>
                <button
                  type="button"
                  onClick={() => {
                    // this.setState({ articlesSwitch: true });
                    this.props.updateGroupNotificationSwitchForUser(
                      selectedGroupId,
                      'articleNotificationSwitch',
                      true
                    );
                  }}
                  className={`${
                    notificationSwitchStates.articleNotificationSwitch
                      ? 'button is-success'
                      : 'button'
                  }`}
                >
                  Yes
                </button>
              </div>
            </div>
            {/* ------------------------------------------------------------- */}
            {/* ------------------------------------------------------------- */}
          </div>
        </form>
      );
    } else {
      return (
        <form onSubmit={this.handleSubmit}>
          <div className={'box'}>
            <h4 className="title is-4">Notification Settings</h4>
          </div>
        </form>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    group: state.group,
    notification: state.notification,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateGroupNotificationSwitchForUser: (
      selectedGroupId,
      notificationSwitchName,
      switchState
    ) =>
      dispatch(
        updateGroupNotificationSwitchForUser(
          selectedGroupId,
          notificationSwitchName,
          switchState
        )
      ),
    fetchGroupNotificationStateForUser: groupId =>
      dispatch(fetchGroupNotificationStateForUser(groupId)),
    resetSwitchState: () => dispatch(resetSwitchState()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsSettingsBox);
