import React, { Component } from 'react';
import { connect } from 'react-redux';
import { joinGroup } from '../../store/Reducers/groupReducer';

const joinGroupForm = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
};

const submitFormButton = {
  flex: '1',
  textAlign: 'center',
  alignSelf: 'flex-end',
};

class JoinGroup extends Component {
  state = {
    groupName: '',
    password: '',
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.joinGroup(this.state);
    document.getElementById('groupNameJoinName').value = '';
    document.getElementById('groupNameJoinPassword').value = '';
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className={'box'}>
          <h4 className="title is-4">Join A Group</h4>
          <div style={joinGroupForm}>
            <div>
              <label>Group Name</label>
              <input
                placeholder="Enter Group Name"
                className="input"
                type="text"
                id="groupNameJoinName"
                name="groupName"
                onChange={this.handleChange}
              />
            </div>

            <div>
              <label>Member Password</label>
              <input
                className="input"
                type="password"
                id="groupNameJoinPassword"
                name="password"
                onChange={this.handleChange}
              />
            </div>

            <div style={submitFormButton}>
              <button className="button">Join Group</button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    joinGroup: groupInfo => dispatch(joinGroup(groupInfo)),
  };
};

export default connect(null, mapDispatchToProps)(JoinGroup);
