import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  ArticleDashboard,
  ArticleDiscussionDetail,
  AddPrayerForm,
  BannerDashboard,
  BannerDiscussionDetail,
  Home,
  Info,
  LeaderDashboard,
  LeaderLogin,
  LoadingPage,
  LoginSignup,
  MemberLogin,
  MemberSignUp,
  MessageDashboard,
  MessageDiscussionDetail,
  PhotoDashboard,
  PrayerDashboard,
  PrayerDetail,
  ProfileDashboard,
  ScriptureDashboard,
  ScriptureDiscussionDetail,
  Signup,
  UserHome,
  WorshipDashboard,
  WorshipDiscussionDetail,
  NotificationsSettingsBox,
} from './components';

import { fetchGroups } from './store/Reducers/groupReducer';
import { me } from './store';

/**
 * COMPONENT
 */
class Routes extends Component {
  componentDidMount() {}

  render() {
    const { auth } = this.props;

    return (
      <Switch>
        {/* Routes placed here are available to all visitors */}
        <Route exact path="/" component={MemberLogin} />
        <Route exact path="/login" component={MemberLogin} />
        {/* <Route exact path="/leaderlogin" component={LeaderLogin} /> */}
        <Route exact path="/signup" component={MemberSignUp} />
        <Route exact path="/info" component={Info} />
        {auth.uid && (
          <Switch>
            <Route exact path="/home" component={Home} />
            <Route exact path="/loadingpage" component={LoadingPage} />
            <Route exact path="/banner" component={BannerDashboard} />
            <Route
              exact
              path="/banner/:id"
              component={BannerDiscussionDetail}
            />
            <Route exact path="/prayers" component={PrayerDashboard} />
            <Route exact path="/prayers/:id" component={PrayerDetail} />
            {/* <Route exact path="/addprayer" component={AddPrayerForm} /> */}
            <Route exact path="/photos" component={PhotoDashboard} />
            <Route exact path="/groupprofile" component={ProfileDashboard} />
            <Route
              exact
              path="/settings"
              component={NotificationsSettingsBox}
            />
            <Route exact path="/leaderdashboard" component={LeaderDashboard} />
            <Route exact path="/scripture" component={ScriptureDashboard} />
            <Route
              exact
              path="/scripture/:id"
              component={ScriptureDiscussionDetail}
            />
            <Route exact path="/worship" component={WorshipDashboard} />
            <Route
              exact
              path="/worship/:id"
              component={WorshipDiscussionDetail}
            />
            <Route exact path="/message" component={MessageDashboard} />
            <Route
              exact
              path="/message/:id"
              component={MessageDiscussionDetail}
            />
            <Route exact path="/article" component={ArticleDashboard} />
            <Route
              exact
              path="/article/:id"
              component={ArticleDiscussionDetail}
            />
          </Switch>
        )}
        {/* Displays our Login component as a fallback */}
        <Route component={MemberLogin} />
      </Switch>
    );
  }
}

/**
 * CONTAINER
 */
const mapStateToProps = state => {
  return {
    // Being 'logged in' for our purposes will be defined has having a state.user that has a truthy id.
    // Otherwise, state.user will be an empty object, and state.user.id will be falsey
    // isLoggedIn: !!state.user.id,
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchGroups: () => dispatch(fetchGroups()),

    // loadInitialData() {
    //   dispatch(me());
    // },
  };
};

// The `withRouter` wrapper makes sure that updates are not blocked
// when the url changes
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));

/**
 * PROP TYPES
 */
// Routes.propTypes = {
//   loadInitialData: PropTypes.func.isRequired,
//   isLoggedIn: PropTypes.bool.isRequired,
// };
